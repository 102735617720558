export const FIELD = {
  name: 'desktop-search',
  icon: 'search',
  iconVariant: 'search',
  iconColor: 'text-turquoise-500',
  placeholder: 'Search enterprise ID or company name',
};

export const BUTTON = {
  icon: 'search',
  className: 'flex-none lg:ml-1.5',
};

export const DEBOUNCE = 500;
