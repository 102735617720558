import React from 'react';
import { BreakPoints } from '@kanda-form-components/library';

import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = (props) => (
  <BreakPoints
    desktop={<Desktop {...props} />}
    mobile={<Mobile {...props} />}
  />
);

ViewComponent.displayName = 'LockOutBannerer-Modal-view';

export default ViewComponent;
