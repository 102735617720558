import React from 'react';
// import { BreakPoints } from '@kanda-form-components/library';
import { Redirect } from 'react-router-dom';

// import Mobile from './Mobile';
// import Desktop from './Desktop';
// import Container from './NewApplication-container';

const ViewComponent = () => <Redirect to="/" />;

ViewComponent.displayName = 'NewApplication-view';

export default ViewComponent;
