export const FILTER_TYPE = {
  STATUS: 'status',
};

export const BUTTON_PROPS = {
  label: 'Clear all',
  size: 32,
  className: 'mb-2',
  variant: 'ghost',
};
