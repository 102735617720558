import React from 'react';
import PropTypes from 'prop-types';
import { Text, Price } from '@kanda-form-components/library';

import Container from './Breakdown-container';
import { PRICE_PROPS, CLASS_NAMES } from './Breakdown-constants';

const ViewComponent = ({ loan, isLoading }) => (
  <Container loan={loan}>
    {({ loanValue, deposit, price }) => (
      <div className={CLASS_NAMES.container}>
        {(isLoading || loanValue) && (
          <div className={CLASS_NAMES.row}>
            <Text
              className={CLASS_NAMES.heading}
              text="Loan amount"
              isLoading={isLoading}
              skeletonProps={{
                width: 100,
              }}
            />
            <Price isLoading={isLoading} amount={loanValue} {...PRICE_PROPS} />
          </div>
        )}
        {(isLoading || deposit) && (
          <div className={CLASS_NAMES.row}>
            <Text
              className={CLASS_NAMES.heading}
              text="Deposit"
              isLoading={isLoading}
              skeletonProps={{
                width: 100,
              }}
            />
            <Price isLoading={isLoading} amount={deposit} {...PRICE_PROPS} />
          </div>
        )}
        <div className={CLASS_NAMES.row}>
          <Text
            className={CLASS_NAMES.heading}
            text="Total"
            isLoading={isLoading}
            skeletonProps={{
              width: 100,
            }}
          />
          <Price isLoading={isLoading} amount={price} {...PRICE_PROPS} />
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  loan: {},
  isLoading: false,
};

ViewComponent.propTypes = {
  /**
   * Loan object
   */
  loan: PropTypes.object,
  /**
   * Loan is loading
   */
  isLoading: PropTypes.bool,
};

ViewComponent.displayName = 'LoanDetails-Breakdown-view';

export default ViewComponent;
