import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { withEnterpriseAuthWrapper } from '@kanda-api/library';

import Home from './Home';
import Login from './Login';
import Logout from './Logout';
import ResetPassword from './ResetPassword';
import LoanDetails from './LoanDetails';
import NewApplication from './NewApplication';
import ForgotPassword from './ForgotPassword';

import config from '../config';

const PageArray = {
  [config.URLS.LOGIN]: Login,
  [config.URLS.LOGOUT]: Logout,
  [config.URLS.FORGOT_PASSWORD]: ForgotPassword,
  [config.URLS.RESET_PASSWORD]: withEnterpriseAuthWrapper(ResetPassword),
  [config.URLS.NEW_APPLICATION]: withEnterpriseAuthWrapper(NewApplication),
  [config.URLS.LOAN_DETAILS]: withEnterpriseAuthWrapper(LoanDetails),
  [config.URLS.HOME]: withEnterpriseAuthWrapper(Home),
};

const Pages = () => {
  const location = useLocation();

  // Effect hides chat widget on route changes
  useEffect(() => {
    if (!window.HelpCrunch) return;
    setTimeout(() => {
      window.HelpCrunch('hideChatWidget');
    }, 120);
  }, [location]);

  return (
    <Switch>
      {Object.entries(PageArray).map(([path, Page]) => (
        <Route path={path} key={path}>
          <Page />
        </Route>
      ))}
    </Switch>
  );
};

export default Pages;
