import { useState, useEffect, useCallback } from 'react';
import { useLoadData, EnterprisePortalService } from '@kanda-api/library';

import { fillForm } from './DownloadSatNote-functions';

const ContainerComponent = ({ children, loan }) => {
  const [pdf, setPdf] = useState(null);

  const { data: profile } = useLoadData([
    EnterprisePortalService.retrieveProfile,
  ]);

  const fullReference = `${profile?.enterprise?.enterpriseId}/${loan?.reference}`;

  /**
   * onClick function for button, creates PDF and attached to anchor
   */
  const onClick = useCallback(() => {
    // Create blob
    const blob = new Blob([pdf], { type: 'application/pdf' });
    // Add PDF name
    const name = `Satisfaction_Note_${fullReference}.pdf`;
    // Create PDF URL
    const url = window.URL.createObjectURL(blob);
    // Create link component
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Set link attributes
      link.setAttribute('href', url);
      link.setAttribute('download', name);
      link.style.visibility = 'hidden';
      // append link to body
      document.body.appendChild(link);
      // click link
      link.click();
      // remove link
      document.body.removeChild(link);
    }
  }, [fullReference, pdf]);

  // Effect generates the filled PDF
  useEffect(() => {
    // If application is null, return
    if (!loan || !profile) return;
    // Call fillForm function
    fillForm(loan, profile, setPdf);
  }, [loan, profile]);

  return children({
    onClick,
    disabled: !pdf,
  });
};

ContainerComponent.displayName =
  'LoanDetails-Buttons-DownloadSatNote-container';

export default ContainerComponent;
