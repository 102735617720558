import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@kanda-form-components/library';

import Container from './Status-container';
import { SKELETON_PROPS } from './Status-constants';

const ViewComponent = ({ loan, isLoading }) => (
  <Container loan={loan}>
    {({ text, classNames }) => (
      <div className={classNames.wrapper}>
        <Text
          className={classNames.text}
          isLoading={isLoading}
          text={text}
          skeletonProps={SKELETON_PROPS}
        />
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  loan: {},
  isLoading: false,
};

ViewComponent.propTypes = {
  /**
   * Loan object
   */
  loan: PropTypes.object,
  /**
   * Loan is loading
   */
  isLoading: PropTypes.bool,
};

ViewComponent.displayName = 'LoanDetails-Status-view';

export default ViewComponent;
