import { FINANCE_STATUSES } from '../../../../constants';

const ContainerComponent = ({ children, loan }) => {
  const financeStatus = loan?.status
    ? FINANCE_STATUSES[loan.status.replace(/_/g, ' ')]
    : FINANCE_STATUSES.OFFERED;

  // DEV_NOTE: this replace shouldn't be needed once BE is fixed
  const { label, color, highlight } = financeStatus || {
    label: '',
    color: '',
    highlight: '',
  };

  const classNames = {
    text: `text-12-18-em text-${color}`,
    wrapper: `flex p-2 rounded-lg bg-${highlight} ml-auto md:ml-0 mb-auto`,
  };

  return children({
    text: label,
    classNames,
  });
};

ContainerComponent.displayName = 'LoanDetails-Status-container';

export default ContainerComponent;
