import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, Button } from '@kanda-form-components/library';

const ViewComponent = ({ onClick }) => (
  <div className="flex flex-col md:flex-row p-6 mb-10 border border-green-300 rounded-lg bg-green-100">
    <Icon
      size={64}
      icon="error"
      stroke="green-500"
      className="hidden md:block mr-6 my-auto"
    />
    <div className="flex flex-col my-auto md:mr-auto">
      <Text
        text="Claim your account on Kanda's new dashboard"
        className="text-18-22-em mb-1"
      />
      <Text
        text="From August 8th you can only send sat notes from this portal. Claim your new account to send loans."
        className="text-14-22 text-neutral-600 mb-1"
      />
    </div>
    <Button.Text
      label="Claim account"
      icon="arrow-right"
      variant="gradient"
      className="whitespace-nowrap mt-6 md:my-auto md:ml-6"
      onClick={onClick}
    />
  </div>
);

ViewComponent.displayName = 'BannLockOutBannerer-Claim-View';

ViewComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ViewComponent;
