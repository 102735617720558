import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  ConfigWrapper,
  CommonWrapper,
  DesktopFooter,
} from '@kanda-form-components/library';
import { DesktopHeader } from '..';

import Container from './AppWrapper-container';

const ViewComponent = ({ children }) => (
  <Container>
    {({ homeLinkProps }) => (
      <ConfigWrapper
        homeLinkProps={homeLinkProps}
        linkComponent={Link}
        desktopFooter={DesktopFooter}
        desktopHeader={DesktopHeader}
      >
        <CommonWrapper>{children}</CommonWrapper>
      </ConfigWrapper>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  children: null,
};

ViewComponent.displayName = 'AppWrapper-view';

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ViewComponent;
