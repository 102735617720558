import React from 'react';
import { Modal } from '@kanda-form-components/library';

import { IDS } from '../Filter-constants';
import { Button, Options } from '../components';

const ViewComponent = () => (
  <>
    <Button {...IDS} />
    <Modal.Container id={IDS.modalId}>
      {({ handleClose, ref }) => (
        <Modal.Layout.SlideUp onClose={handleClose} ref={ref}>
          <Options handleClose={handleClose} />
        </Modal.Layout.SlideUp>
      )}
    </Modal.Container>
  </>
);

ViewComponent.displayName = 'Banner-FilterButton-Mobile-View';

export default ViewComponent;
