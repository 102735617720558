import React from 'react';
import { Modal, Text } from '@kanda-form-components/library';

import Content from '../Content';

import { MODAL_ID } from '../../../LockOutBanner-constants';

const ViewComponent = () => (
  <Modal.Container opacity={50} id={MODAL_ID}>
    {({ handleClose }) => (
      <Modal.Layout.Center onClose={handleClose}>
        <div className="flex flex-col w-120">
          <Text text="Claim your new account" className="text-18-22-em mb-6" />
          <Content />
        </div>
      </Modal.Layout.Center>
    )}
  </Modal.Container>
);

ViewComponent.displayName = 'SendSatNoteModal-Desktop-view';

export default ViewComponent;
