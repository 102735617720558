const SUPPORTED_FORMATS = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/vnd.apple.pages',
];

const MAX_FILE_SIZE = 20 * 1024 * 1024;

export const FIELD_NAMES = {
  quoteFile: 'quoteFile',
  satNoteFile: 'satNoteFile',
};

export const DEFAULT_VALUES = {
  [FIELD_NAMES.quoteFile]: undefined,
  [FIELD_NAMES.satNoteFile]: undefined,
};

export const FIELDS = {
  quote: {
    name: FIELD_NAMES.quoteFile,
    accept: SUPPORTED_FORMATS,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
  },
  satNote: {
    name: FIELD_NAMES.satNoteFile,
    accept: SUPPORTED_FORMATS,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE,
  },
};

export const BUTTON_PROPS = {
  cancel: {
    submit: false,
    label: 'Cancel',
    variant: 'outline',
    className: 'mr-4 w-1/2 md:w-52',
  },
  submit: {
    submit: true,
    variant: 'gradient',
    label: 'Confirm',
    className: 'w-1/2 md:w-52',
  },
};
