export const BUTTON_PROPS = {
  mobile: {
    variant: 'turquoise',
    icon: 'plus',
    size: '40-20',
    id: 'user-banner-new-loan',
    className: 'whitespace-nowrap',
    text: 'New',
  },
  desktop: {
    variant: 'solid',
    icon: 'plus',
    size: 40,
    left: true,
    id: 'user-banner-new-loan',
    text: 'New',
    label: 'New Loan',
    className: 'whitespace-nowrap',
  },
};
