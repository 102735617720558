import { useHistory } from 'react-router-dom';

const ContainerComponent = ({ children }) => {
  const history = useHistory();

  const handleClick = () => history.goBack();

  return children({
    handleClick,
  });
};

ContainerComponent.displayName = 'Backlink-container';

export default ContainerComponent;
