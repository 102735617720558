import React from 'react';
import { Link } from 'react-router-dom';
import { BreakPoints, Button } from '@kanda-form-components/library';

import { BUTTON_PROPS } from './NewLoanButton-constants';

import { URLS } from '../../../../../../config';

const ViewComponent = () => (
  <Link to={URLS.NEW_APPLICATION} className="ml-2">
    <BreakPoints
      mobile={<Button.Icon {...BUTTON_PROPS.mobile} />}
      desktop={<Button.Text {...BUTTON_PROPS.desktop} />}
    />
  </Link>
);

ViewComponent.displayName = 'Banner-NewLoanButton-view';

export default ViewComponent;
