import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Layout } from '@kanda-form-components/library';

import Container from './Mobile-container';

import { LoanCard, JobsPlaceholder, NoResults } from './components';
import { Banner, MobileList } from '../components';

const ViewComponent = () => (
  <Container>
    {({ isTableVisible, showNoResults, ...props }) => (
      <Layout
        bg="neutral-50"
        stickyFooter
        stickyHeader
        noBorder
        header={
          <Header.Main
            help={false}
            className="bg-neutral-50"
            linkComponent={Link}
          />
        }
        headerBg="neutral-50"
      >
        <Banner />
        {isTableVisible ? (
          <MobileList {...props} card={LoanCard} />
        ) : (
          <>{showNoResults ? <NoResults /> : <JobsPlaceholder />}</>
        )}
      </Layout>
    )}
  </Container>
);
ViewComponent.displayName = 'Home-Mobile-view';

export default ViewComponent;
