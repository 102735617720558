import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  EnterprisePortalService,
  EnterpriseUserAuthService,
  useMutate,
  useLoadData,
} from '@kanda-api/library';
import { useYupForm, useToast } from '@kanda-form-components/library';

import { FORGOT_PASSWORD_SCHEMA } from './ForgotPassword-validation';
import { getError } from './ForgotPassword-functions';

import { URLS } from '../../config';

const ContainerComponent = ({ children }) => {
  const { mutate, isLoading: isSubmitting } = useMutate(
    EnterprisePortalService.resetPassword,
  );

  const { data: isUserLoggedIn } = useLoadData([
    EnterpriseUserAuthService.key.isUserLoggedIn,
  ]);

  const { data: profile } = useLoadData(
    isUserLoggedIn && [EnterprisePortalService.retrieveProfile],
  );

  const { showSuccess, showError } = useToast();

  const { push } = useHistory();

  const schema = FORGOT_PASSWORD_SCHEMA;

  const form = useYupForm({ schema });

  const disabled = isSubmitting || !form.formState.isDirty;

  const onSubmit = () => {
    const { email } = form.getValues();
    mutate(email).then(({ error }) => {
      if (error) {
        showError(getError(error.errors));
        return;
      }
      showSuccess('Password reset, please check your email for instructions');
    });
  };

  // EFfect pushes user to HOME if the user is logged in
  useEffect(() => {
    if (!profile || profile?.enterpriseUser?.isFirstTimeLogin) return;
    push(URLS.HOME);
  }, [push, profile]);

  return children({
    onSubmit,
    form,
    disabled,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'ForgotPassword-container';

export default ContainerComponent;
