import React from 'react';
import PropTypes from 'prop-types';
import { Button, Field } from '@kanda-form-components/library';
import { Link } from 'react-router-dom';

import {
  CLASS_NAMES,
  FORGOT_PASSWORD_BUTTON,
  TITLE,
  DESCRIPTION,
  FIELDS,
  ERROR,
} from './Content-constants';

import { URLS } from '../../../../config';

const ViewComponent = ({ error }) => (
  <>
    <div className={CLASS_NAMES.headerWrapper}>
      <p className={CLASS_NAMES.title}>{TITLE}</p>
      <p className={CLASS_NAMES.description}>{DESCRIPTION}</p>
    </div>
    <Field.Input {...FIELDS.username} />
    <Field.PasswordInput
      {...FIELDS.password}
      helperText={
        <Link to={URLS.FORGOT_PASSWORD}>
          <Button.InlineLink {...FORGOT_PASSWORD_BUTTON} />
        </Link>
      }
    />
    {error && <p className={CLASS_NAMES.error}>{ERROR}</p>}
    <Field.BooleanInput {...FIELDS.cookie} />
  </>
);

ViewComponent.defaultProps = {
  error: undefined,
};

ViewComponent.displayName = 'Login-Content-view';

ViewComponent.propTypes = {
  /**
   * Error
   */
  error: PropTypes.object,
};

export default ViewComponent;
