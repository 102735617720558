import * as Yup from 'yup';

export const FORGOT_PASSWORD_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('You must provide a valid email address')
    .required(
      'You must provide the email associated with the account to reset the password of',
    )
    .typeError(
      'You must provide the email associated with the account to reset the password of',
    ),
});
