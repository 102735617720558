import { useContext } from 'react';

import Context from '../Context';

const ContainerComponent = ({ children }) => {
  const { loan, isLoading, satNoteSentRefresh, fulfilmentRefresh } =
    useContext(Context);

  return children({
    loan,
    isLoading,
    satNoteSentRefresh,
    fulfilmentRefresh,
  });
};

ContainerComponent.displayName = 'LoanDetails-Desktop-container';

export default ContainerComponent;
