import React from 'react';
import { BreakPoints } from '@kanda-form-components/library';

import Container from './Banner-container';
import {
  Title,
  Filter,
  ActiveFilters,
  DesktopSearch,
  LockOutBanner,
} from './components';
import { CLASS_NAMES } from './Banner-constants';

const ViewComponent = () => (
  <Container>
    {({ showActiveFilters, isLoading, showOptions }) => (
      <div className={CLASS_NAMES.container}>
        <LockOutBanner />
        <div className={CLASS_NAMES.header}>
          <Title />
          <BreakPoints
            desktop={showOptions && <DesktopSearch isLoading={isLoading} />}
          />
          {showOptions && (
            <div className={CLASS_NAMES.options}>
              <Filter isLoading={isLoading} />
            </div>
          )}
        </div>
        <BreakPoints
          mobile={showOptions && <DesktopSearch isLoading={isLoading} />}
        />
        {showActiveFilters && <ActiveFilters />}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Banner-view';

export default ViewComponent;
