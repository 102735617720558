const ContainerComponent = ({
  children,
  customerDetails: customer,
  reference,
}) => {
  const name = customer?.firstName
    ? `${customer?.firstName} ${customer?.lastName}`
    : undefined;
  const email = customer?.email || undefined;
  const address = customer?.currentAddress?.postCode
    ? `${
        customer?.currentAddress?.houseNumber ||
        customer?.currentAddress?.houseName ||
        customer?.currentAddress?.flat ||
        ''
      } ${customer?.currentAddress.street}, ${
        customer?.currentAddress?.city
      }, ${customer?.currentAddress?.postCode}`
    : undefined;

  const parts = reference ? reference.split('/') : [];

  return children({
    name,
    email,
    address,
    reference: parts.slice(1).join('/'),
  });
};

ContainerComponent.displayName = 'LoanCard-container';

export default ContainerComponent;
