import { useContext } from 'react';

import { FILTER_TYPE } from './ActiveFilters-constants';

import Context from '../../../../Context';
import { FINANCE_STATUSES } from '../../../../../../constants';

const ContainerComponent = ({ children }) => {
  const { filter, updateFilter } = useContext(Context);

  const statusFilter = filter?.status && {
    type: FILTER_TYPE.STATUS,
    label: FINANCE_STATUSES[filter.status].label,
  };

  const activeFilters = [statusFilter].filter(Boolean);

  const showClearAllFilters = activeFilters.length > 1;

  const onClearAll = () => {
    updateFilter('status', undefined);
  };
  return children({ activeFilters, showClearAllFilters, onClearAll });
};

ContainerComponent.displayName = 'ActiveFilters-container';

export default ContainerComponent;
