import React from 'react';
import { Modal, Header, Button } from '@kanda-form-components/library';

import Content from '../Content';

import { MODAL_ID } from '../../../LockOutBanner-constants';

const ViewComponent = () => (
  <Modal.Container id={MODAL_ID}>
    {({ handleClose }) => (
      <Modal.Layout.FullScreen
        header={
          <Header.Base
            className="text-style-f-em text-neutral-700"
            help
            options={[
              <Button.Icon key="close" onClick={handleClose} icon="close" />,
            ]}
          >
            Claim new account
          </Header.Base>
        }
        onClose={handleClose}
      >
        <Content />
      </Modal.Layout.FullScreen>
    )}
  </Modal.Container>
);

ViewComponent.displayName = 'LockOutBannerer-Modal-Mobile-view';

export default ViewComponent;
