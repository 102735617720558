import React from 'react';
import { BreakPoints } from '@kanda-form-components/library';

import Container from './SendSatNote-container';
import Base from '../Base';
import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = (props) => (
  <Container>
    {({ onClick }) => (
      <>
        <Base
          onClick={onClick}
          bgColor="lavender-200"
          textColor="neutral-000"
          text="Send satisfaction note"
          icon="send"
        />
        <BreakPoints
          desktop={<Desktop {...props} />}
          mobile={<Mobile {...props} />}
        />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'LoanDetails-Buttons-SendSatNote-view';

export default ViewComponent;
