import React from 'react';
import { BreakPoints } from '@kanda-form-components/library';

import Container from './Fulfil-container';
import Base from '../Base';
import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = (props) => (
  <Container>
    {({ onClick }) => (
      <>
        <Base
          onClick={onClick}
          bgColor="green-400"
          textColor="neutral-000"
          text="Fulfil order"
          icon="note"
          className="mb-4"
        />
        <BreakPoints
          desktop={<Desktop {...props} />}
          mobile={<Mobile {...props} />}
        />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'LoanDetails-Buttons-Fulfil-view';

export default ViewComponent;
