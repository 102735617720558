import React from 'react';
import PropTypes from 'prop-types';
import { BreakPoints } from '@kanda-form-components/library';

import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = (props) => (
  <BreakPoints
    mobile={<Mobile {...props} />}
    desktop={<Desktop {...props} />}
  />
);

ViewComponent.displayName = 'Filter-view';

ViewComponent.propTypes = {
  /**
   * Title
   */
  title: PropTypes.string.isRequired,
  /**
   * Customer
   */
  label: PropTypes.string.isRequired,
  /**
   * On click function
   */
  onClick: PropTypes.func.isRequired,
  /**
   * icon
   */
  icon: PropTypes.string.isRequired,
};

export default ViewComponent;
