export const generateCustomerName = (customerDetails) =>
  customerDetails?.firstName
    ? `${customerDetails?.firstName} ${customerDetails?.lastName}`
    : 'Customer';

export const generateCustomerAddress = (customerAddress) => {
  const number =
    customerAddress?.houseNumber ||
    customerAddress?.houseName ||
    customerAddress?.flat ||
    null;

  if (!number)
    return {
      line: '',
      postcode: '',
    };

  const line = `${number} ${customerAddress?.street}`;

  const postcode = customerAddress?.postCode || null;

  return {
    line,
    postcode,
  };
};

export const generateContactDetails = (customerDetails) => {
  const phone = customerDetails?.telephone || customerDetails?.mobile || '';
  const email = customerDetails?.email || '';

  return {
    phone,
    email,
  };
};
