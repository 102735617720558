import React from 'react';
import { DesktopLayout, Table } from '@kanda-form-components/library';

import Container from './Desktop-container';
import { TABLE_COLUMNS, DEFAULT_COLUMN } from './Desktop-constants';
import { Banner } from '../components';
import { JobsPlaceholder, NoResults } from './components';

const ViewComponent = () => (
  <Container>
    {({ isTableVisible, showNoResults, ...props }) => (
      <DesktopLayout.Default>
        <DesktopLayout.Default.Content>
          <Banner />
          {isTableVisible ? (
            <Table
              columns={TABLE_COLUMNS}
              defaultColumn={DEFAULT_COLUMN}
              hoverPopover={null}
              {...props}
            />
          ) : (
            <>{showNoResults ? <NoResults /> : <JobsPlaceholder />}</>
          )}
        </DesktopLayout.Default.Content>
      </DesktopLayout.Default>
    )}
  </Container>
);
ViewComponent.displayName = 'Home-Desktop-view';

export default ViewComponent;
