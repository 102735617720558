import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@kanda-form-components/library';

import { BUTTON_PROPS } from './Button-constants';

import { URLS } from '../../../../../../../config';

const ViewComponent = () => (
  <Link to={URLS.NEW_APPLICATION}>
    <Button.Text {...BUTTON_PROPS} />
  </Link>
);

ViewComponent.displayName = 'JobsPlaceholder-Button-view';

export default ViewComponent;
