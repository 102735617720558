import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Pages from './pages';
import { AppWrapper, Menu } from './components';

function App() {
  return (
    <Router>
      <AppWrapper>
        <Pages />
        <Menu />
      </AppWrapper>
    </Router>
  );
}

export default App;
