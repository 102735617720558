import React from 'react';
import PropTypes from 'prop-types';

import Container from './DownloadSatNote-container';
import Base from '../Base';

const ViewComponent = ({ loan }) => (
  <Container loan={loan}>
    {({ onClick }) => (
      <Base
        onClick={onClick}
        bgColor="neutral-200"
        textColor="green-400"
        text="Satisfaction note"
        icon="download-file"
        className="mb-4"
      />
    )}
  </Container>
);

ViewComponent.defaultProps = {
  loan: {},
};

ViewComponent.propTypes = {
  /**
   * Loan object
   */
  loan: PropTypes.object,
};

ViewComponent.displayName = 'LoanDetails-Buttons-DownloadSatNote-view';

export default ViewComponent;
