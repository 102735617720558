import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon } from '@kanda-form-components/library';

import Container from './Customer-container';
import { SKELETON_PROPS } from './Customer-constants';

const ViewComponent = ({ loan, isLoading }) => (
  <Container loan={loan}>
    {({ name, address, contact }) => (
      <div className="flex flex-col mt-4 md:mt-0">
        <Text
          className="text-24-28-em text-neutral-600 mb-3"
          isLoading={isLoading}
          text={name}
          skeletonProps={SKELETON_PROPS.name}
        />
        {(isLoading || address?.line) && (
          <Text
            className="text-16-24 text-neutral-600"
            isLoading={isLoading}
            text={address?.line}
            skeletonProps={SKELETON_PROPS.line}
          />
        )}
        {(isLoading || address?.postcode) && (
          <Text
            className="text-16-24 text-neutral-600"
            isLoading={isLoading}
            text={address?.postcode}
            skeletonProps={SKELETON_PROPS.postcode}
          />
        )}
        {(isLoading || contact?.email) && (
          <div className="flex flex-row mt-2">
            <Icon
              icon="email"
              size={16}
              isLoading={isLoading}
              stroke="neutral-600"
              className="my-auto"
            />
            <Text
              className="text-16-24 text-neutral-600 ml-3"
              isLoading={isLoading}
              text={contact?.email}
              skeletonProps={SKELETON_PROPS.contact}
            />
          </div>
        )}
        {(isLoading || contact?.phone) && (
          <div className="flex flex-row mt-2">
            <Icon
              icon="phone"
              size={16}
              isLoading={isLoading}
              stroke="neutral-600"
              className="my-auto"
            />
            <Text
              className="text-16-24 text-neutral-600 ml-3"
              isLoading={isLoading}
              text={contact?.phone}
              skeletonProps={SKELETON_PROPS.contact}
            />
          </div>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  loan: {},
  isLoading: false,
};

ViewComponent.propTypes = {
  /**
   * Loan object
   */
  loan: PropTypes.object,
  /**
   * Loan is loading
   */
  isLoading: PropTypes.bool,
};

ViewComponent.displayName = 'LoanDetails-Customer-view';

export default ViewComponent;
