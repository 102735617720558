import { useLoadData, EnterprisePortalService } from '@kanda-api/library';

const ContainerComponent = ({ children, loan }) => {
  const { data: profile } = useLoadData([
    EnterprisePortalService.retrieveProfile,
  ]);

  const id = profile?.enterprise?.enterpriseId || null;

  // DEV_NOTE: this replace shouldn't be needed once BE is fixed
  const showButtons = loan?.status
    ? loan?.status.replace('_', ' ') === 'AWAITING FULFILMENT'
    : false;

  const showFulfil = ['00001', '99999'].includes(id);

  return children({
    showButtons,
    showFulfil,
  });
};

ContainerComponent.displayName = 'LoanDetails-Buttons-container';

export default ContainerComponent;
