import { SKELETON_DATA } from './MobileList-constants';

const ContainerComponent = ({ children, isLoading, rawData }) => {
  const data = isLoading ? SKELETON_DATA : rawData;

  return children({ data });
};

ContainerComponent.displayName = 'MobileList-container';

export default ContainerComponent;
