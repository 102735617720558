import { CLASS_NAMES } from './Base-constants';

const ContainerComponent = ({ children, className, bgColor, textColor }) => {
  const classNames = {
    button: `${CLASS_NAMES.button} bg-${bgColor} ${className}`,
    text: `${CLASS_NAMES.text} text-${textColor}`,
    icon: CLASS_NAMES.icon,
  };

  return children({
    classNames,
    iconStroke: textColor,
  });
};

ContainerComponent.displayName = 'LoanDetails-Buttons-BaseButton-container';

export default ContainerComponent;
