import React from 'react';
import { Link } from 'react-router-dom';
import { DesktopLayout, Button } from '@kanda-form-components/library';

import Container from './Desktop-container';
import {
  // Description,
  CreatedAt,
  Reference,
  Customer,
  Status,
  Buttons,
  Breakdown,
  Subsidy,
} from '../components';

import { URLS } from '../../../config';

const ViewComponent = () => (
  <Container>
    {(props) => (
      <DesktopLayout.Boxed
        help
        options={[
          <Link key="close" to={URLS.HOME}>
            <Button.Icon icon="close" />
          </Link>,
        ]}
      >
        <div className="flex flex-col w-full p-12 my-auto">
          <div className="flex flex-col mb-10">
            <h1 className="text-32-40-em">Loan Details</h1>
            <div className="flex flex-row">
              <CreatedAt {...props} />
              <span className="mx-2">/</span>
              <Reference {...props} />
            </div>
          </div>

          <div className="flex flex-row">
            <div className="flex flex-1 flex-row flex-wrap-reverse justify-between">
              <Customer {...props} />
              <Status {...props} />
            </div>
            <Buttons {...props} />
          </div>

          <div className="mt-8 mb-4 border-t border-neutral-400" />

          <div className="flex flex-row justify-between flex-wrap w-full -ml-6 -mt-6">
            <Breakdown {...props} />
            <Subsidy {...props} />
          </div>
        </div>
      </DesktopLayout.Boxed>
    )}
  </Container>
);
ViewComponent.displayName = 'LoanDetails-Desktop-view';

export default ViewComponent;
