import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EnterpriseUserAuthService, useMutate, useLoadData } from '@kanda-api/library';
import { useYupForm, useToast } from '@kanda-form-components/library';

import { LOGIN_SCHEMA } from './Login-validation';
import { getError } from './Login-functions';

import { URLS } from '../../config';

const ContainerComponent = ({ children }) => {
  const { mutate, isLoading: isSubmitting } = useMutate(EnterpriseUserAuthService.login);

  const { data: isUserLoggedIn } = useLoadData([
    EnterpriseUserAuthService.key.isUserLoggedIn,
  ]);

  const { showError } = useToast();

  const { push } = useHistory();

  const schema = LOGIN_SCHEMA;

  const form = useYupForm({ schema });

  const disabled = isSubmitting || !form.formState.isDirty;

  const login = () => {
    const formValues = form.getValues();
    const payload = {
      username: formValues.username,
      password: formValues.password,
    };
    mutate(payload, formValues.cookie || false).then(({ error }) => {
      if (!error) return;
      showError(getError(error.errors));
    });
  };

  // EFfect pushes user to HOME if the user is logged in
  useEffect(() => {
    if (!isUserLoggedIn) return;
    push(URLS.HOME);
  }, [push, isUserLoggedIn]);

  return children({
    login,
    form,
    disabled,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'Login-container';

export default ContainerComponent;
