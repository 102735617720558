import {
  generateCustomerName,
  generateCustomerAddress,
  generateContactDetails,
} from './Customer-functions';

const ContainerComponent = ({ children, loan }) => {
  const name = generateCustomerName(loan?.customerDetails);

  const address = generateCustomerAddress(
    loan?.customerDetails?.currentAddress,
  );

  const contact = generateContactDetails(loan?.customerDetails);

  return children({
    name: `${name}'s loan`,
    address,
    contact,
  });
};

ContainerComponent.displayName = 'LoanDetails-Customer-container';

export default ContainerComponent;
