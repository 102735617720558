import React from 'react';
import { TimeAgo } from '@kanda-form-components/library';

import Container from './Activity-container';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({ label, timestamp, classNames }) => (
      <div className="flex flex-row flex-wrap justify-center">
        <span className={classNames.label}>{label}</span>
        <TimeAgo className={classNames.time} timestamp={timestamp} />
      </div>
    )}
  </Container>
);
ViewComponent.displayName = 'Activity-view';

export default ViewComponent;
