import { FINANCE_BASE_FEE } from '../../../../constants';

export const extractLoanInfo = (rate) => {
  if (!rate)
    return {
      interest: '',
      duration: '',
    };

  if (rate.includes('free'))
    return {
      interest: '0%',
      duration: rate.split('_').slice(-1)[0],
    };

  return {
    interest: `${parseInt(rate.split('_').slice(-1), 10) / 100}%`,
    duration: rate.split('_').slice(-2, -1)[0],
  };
};

export const getPercentageFee = (rates, rate) => {
  if (!rates || !rate) return 0;
  const index = rates.findIndex((userRate) => userRate.name === rate);
  if (index === -1) return 0;
  return rates[index].fee;
};

export const getSubsidy = (rates, rate, price) => {
  if (!rates || !rate || !price) return 4900;

  const baseFee = FINANCE_BASE_FEE[rate || 'interest_free_10'];

  const percentageFee = getPercentageFee(rates, rate);

  return baseFee + Math.ceil((percentageFee * price) / 10000);
};
