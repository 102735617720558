export const SKELETON_PROPS = {
  name: {
    width: 250,
  },
  line: {
    width: 150,
  },
  postcode: {
    width: 80,
  },
  contact: {
    width: 100,
  },
};
