import { URLS } from '../../config/application';

export const MENU_LINKS = [
  { icon: 'account-color', name: 'Log Out', to: URLS.LOGOUT },
];

export const SKELETONS = {
  banner: {
    height: 60,
  },
  companyName: {
    width: 160,
  },
  plan: {
    width: 120,
  },
};
