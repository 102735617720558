export const CLASS_NAMES = {
  container: 'flex flex-col max-h-96 md:max-h-96 overflow-auto p-2',
  base: 'transition duration-100 ease-in-out py-2.5 mb-2 text-left md:w-48 md:px-3 md:rounded-lg md:hover:bg-turquoise-100 md:hover:text-turquoise-300',
  inactive: 'text-style-f text-neutral-700 md:text-neutral-600',
  active:
    'text-style-f-em text-turquoise-300 md:bg-turquoise-100 md:text-style-f md:text-turquoise-300',
};

export const DEFAULT_FILTERS = [
  {
    label: 'All jobs',
  },
];
