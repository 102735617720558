export const CLASS_NAMES = {
  headerWrapper: 'md:text-center',
  title: 'text-style-b mb-4 text-neutral-900',
  description:
    'text-style-g mb-8 text-neutral-700 whitespace-pre-line md:text-style-f md:text-neutral-600',
  error: 'text-style-h text-red-200 mb-4 -mt-4',
};

export const TITLE = 'Login';

export const DESCRIPTION =
  'Welcome back to Kanda! Please login\nto your account to continue';

export const ERROR = 'Invalid credentials';

export const FIELDS = {
  username: {
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'username',
    name: 'username',
    label: 'Username',
    placeholder: 'Your username',
  },
  password: {
    name: 'password',
    label: 'Password',
    placeholder: 'Your password',
    autoComplete: 'current-password',
  },
  cookie: {
    name: 'cookie',
    fieldText: 'Keep me logged in',
    handle: 'checkbox',
  },
};

export const FORGOT_PASSWORD_BUTTON = {
  label: 'Forgot password',
  className: 'whitespace-nowrap',
  variant: 'turquoise',
};
