import React from 'react';
import PropTypes from 'prop-types';

import Container from './Buttons-container';
import { Fulfil, DownloadSatNote, SendSatNote } from './components';

const ViewComponent = ({
  loan,
  isLoading,
  satNoteSentRefresh,
  fulfilmentRefresh,
}) => (
  <Container loan={loan}>
    {({ showButtons, showFulfil }) =>
      !isLoading &&
      showButtons && (
        <div className="flex flex-col md:ml-6">
          {showFulfil && (
            <>
              <Fulfil loan={loan} fulfilmentRefresh={fulfilmentRefresh} />
              <DownloadSatNote loan={loan} />
            </>
          )}
          <SendSatNote loan={loan} satNoteSentRefresh={satNoteSentRefresh} />
        </div>
      )
    }
  </Container>
);

ViewComponent.defaultProps = {
  loan: {},
  isLoading: false,
  fulfilmentRefresh: undefined,
  satNoteSentRefresh: undefined,
};

ViewComponent.propTypes = {
  /**
   * Loan object
   */
  loan: PropTypes.object,
  /**
   * Loan is loading
   */
  isLoading: PropTypes.bool,
  /**
   * Refresh on sat note sent function
   */
  fulfilmentRefresh: PropTypes.func,
  /**
   * Refresh on sat note sent function
   */
  satNoteSentRefresh: PropTypes.func,
};

ViewComponent.displayName = 'LoanDetails-Buttons-view';

export default ViewComponent;
