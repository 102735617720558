import React from 'react';

import Container from './LockOutBanner-container';
import { Claim, Locked, Modal } from './components';

const ViewComponent = () => (
  <Container>
    {({ showTemporary, showLocked, handleShowModal }) => (
      <>
        {showTemporary && <Claim onClick={handleShowModal} />}
        {showLocked && <Locked onClick={handleShowModal} />}
        <Modal />
      </>
    )}
  </Container>
);

ViewComponent.displayName = 'LockOutBannerer-View';

export default ViewComponent;
