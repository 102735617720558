import {
  Button,
  FormTheme,
  UncontrolledField,
  BreakPoints,
} from '@kanda-form-components/library';
import React from 'react';

import { FIELD, BUTTON } from './DesktopSearch-constants';
import Container from './DesktopSearch-container';

const ViewComponent = () => (
  <Container>
    {({ query, searchVisible, onSearch, onBlur, onChange, autoFocus }) => (
      <div className="flex flex-1 flex-row lg:ml-2">
        <FormTheme variant="inline">
          {searchVisible ? (
            <BreakPoints
              mobile={
                <div className="h-10 flex w-full flex-col justify-center -ml-1.5">
                  <UncontrolledField.Input
                    {...FIELD}
                    defaultValue={query}
                    onBlur={onBlur}
                    autoFocus={autoFocus}
                    onChange={onChange}
                  />
                </div>
              }
              desktop={
                <UncontrolledField.Input
                  {...FIELD}
                  defaultValue={query}
                  onBlur={onBlur}
                  autoFocus={autoFocus}
                  onChange={onChange}
                />
              }
            />
          ) : (
            <Button.Icon onClick={onSearch} {...BUTTON} />
          )}
        </FormTheme>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DesktopSearch-view';

export default ViewComponent;
