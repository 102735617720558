import React from 'react';

import { Popover } from '@kanda-form-components/library';
import { IDS } from '../Filter-constants';
import { Button, Options } from '../components';

const ViewComponent = () => (
  <Popover.Standard right button={<Button {...IDS} />}>
    {({ handleClose }) => <Options handleClose={handleClose} />}
  </Popover.Standard>
);

ViewComponent.displayName = 'Banner-FilterButton-Desktop-View';

export default ViewComponent;
