import { capitalize } from '@kanda-utils/library';

import { CLASS_NAMES } from './Activity-constants';

import { FINANCE_STATUSES } from '../../../../constants';

const ContainerComponent = ({ children, status }) => {
  const financeStatus = FINANCE_STATUSES[status.replace(/_/g, ' ')];

  const { label, color } = financeStatus || FINANCE_STATUSES.OFFERED;

  const statusLabel = capitalize(label);

  const classNames = {
    label: `${CLASS_NAMES.label} text-${color}`,
  };

  return children({
    statusLabel,
    classNames,
  });
};

ContainerComponent.displayName = 'Activity-container';

export default ContainerComponent;
