import React from 'react';
import { Layout } from '@kanda-form-components/library';

import { Header } from './components';
import {
  Buttons,
  CreatedAt,
  Reference,
  Status,
  Customer,
  Breakdown,
} from '../components';

import Container from './Mobile-container';

const ViewComponent = () => (
  <Container>
    {(props) => (
      <Layout
        bg="neutral-50"
        stickyFooter
        stickyHeader
        noBorder
        footer={<Buttons {...props} />}
        header={<Header />}
        headerBg="neutral-50"
      >
        <div className="w-full flex flex-col flex-1 -mt-6">
          <div className="w-full flex flex-col mb-4">
            <CreatedAt {...props} />
            <Reference {...props} />
          </div>

          <div className="flex flex-col p-6 rounded-lg shadow-a bg-neutral-000 border border-neutral-300">
            <Status {...props} />
            <Customer {...props} />

            <div className="border-t border-neutral-400 my-4" />

            <Breakdown {...props} />
          </div>
        </div>
      </Layout>
    )}
  </Container>
);
ViewComponent.displayName = 'LoanDetails-Mobile-view';

export default ViewComponent;
