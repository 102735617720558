import { useCallback } from 'react';
import { useLoadData, EnterprisePortalService } from '@kanda-api/library';
import { useParams } from 'react-router-dom';

const ContainerComponent = ({ children }) => {
  const { id } = useParams();

  const {
    data,
    isValidating: isLoansLoading,
    mutate: revalidate,
  } = useLoadData([EnterprisePortalService.retrieveLoanApplications]);

  const { isValidating: isLoadingProfile } = useLoadData([
    EnterprisePortalService.retrieveProfile,
  ]);

  const isLoading = isLoansLoading || isLoadingProfile;

  const loans = data?.loanApplications || [];

  const index = isLoading ? null : loans.findIndex((loan) => loan.id === id);

  const loan = isLoading || index === -1 ? {} : loans[index];

  const satNoteSentRefresh = useCallback(async () => {
    const revalidatedData = await revalidate();
    const indx = revalidatedData?.loanApplications.findIndex(
      (revalidatedLoan) => revalidatedLoan.id === id,
    );
    if (indx === -1) return;
    if (revalidatedData.loanApplications[indx].status === 'SAT NOTE SENT')
      return;
    satNoteSentRefresh();
  }, [id, revalidate]);

  const fulfilmentRefresh = useCallback(async () => {
    const revalidatedData = await revalidate();
    const indx = revalidatedData?.loanApplications.findIndex(
      (revalidatedLoan) => revalidatedLoan.id === id,
    );
    if (indx === -1) return;
    if (
      revalidatedData.loanApplications[indx].status === 'FULFILMENT IN REVIEW'
    )
      return;
    fulfilmentRefresh();
  }, [id, revalidate]);

  return children({
    loan,
    isLoading,
    satNoteSentRefresh,
    fulfilmentRefresh,
  });
};
ContainerComponent.displayName = 'LoanInfo-Provider-Container';

export default ContainerComponent;
