import React from 'react';
import PropTypes from 'prop-types';
import { Text, DateTime } from '@kanda-form-components/library';

import Container from './LoanCard-container';

const ViewComponent = ({
  isLoading,
  description,
  status,
  createdAt,
  ...props
}) => (
  <Container {...props}>
    {({ name, email, address, reference }) => (
      <div className="p-6 flex flex-col">
        <Text
          isLoading={isLoading}
          text={reference}
          className="text-14-22-em mb-2"
        />
        <div className="flex flex-row justify-between">
          <div className="flex flex-col w-3/5 pr-2">
            <Text
              isLoading={isLoading}
              text={description}
              className="text-14-22 mb-1"
            />
            <div className="flex flex-col">
              <Text isLoading={isLoading} text={name} className="text-12-18" />
              <Text isLoading={isLoading} text={email} className="text-12-18" />
              <Text
                isLoading={isLoading}
                text={address}
                className="text-12-18"
              />
            </div>
          </div>
          <div className="flex flex-col w-2/5">
            <Text
              isLoading={isLoading}
              text={status}
              className="text-14-22 mb-1 text-right"
            />
            <div className="flex flex-col ml-auto">
              <p className="text-14-22 text-right">Updated:</p>
              <DateTime
                isLoading={isLoading}
                timestamp={createdAt}
                variant="date"
                className="text-14-22 text-right"
              />
              <DateTime
                isLoading={isLoading}
                timestamp={createdAt}
                variant="time"
                className="text-14-22 text-right"
              />
            </div>
          </div>
        </div>
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'LoanCard-view';

ViewComponent.defaultProps = {
  isLoading: false,
  id: undefined,
  description: undefined,
  reference: undefined,
  status: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  customerDetails: undefined,
};

ViewComponent.propTypes = {
  /**
   * Loan data is loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Loan is
   */
  id: PropTypes.string,
  /**
   * Loan description
   */
  description: PropTypes.string,
  /**
   * Loan reference
   */
  reference: PropTypes.string,
  /**
   * Loan status
   */
  status: PropTypes.string,
  /**
   * Date loan was created
   */
  createdAt: PropTypes.number,
  /**
   * Date loan was last updated
   */
  updatedAt: PropTypes.number,
  /**
   * Customer details data
   */
  customerDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    telephone: PropTypes.string,
    mobile: PropTypes.string,
    currentAddress: PropTypes.shape({
      postCode: PropTypes.string,
      city: PropTypes.string,
      flat: PropTypes.string,
      houseNumber: PropTypes.string,
      houseName: PropTypes.string,
      street: PropTypes.string,
    }),
  }),
};

export default ViewComponent;
