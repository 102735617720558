export const SKELETON = {
  width: 40,
  height: 38,
  circle: true,
};

export const IDS = {
  buttonId: 'jobs-banner-filter-button',
  modalId: 'jobs-banner-filter-modal',
};
