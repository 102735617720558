export const FINANCE_BASE_FEE = {
  interest_free_10: 0,
  interest_free_12: 2800,
  interest_free_24: 2800,
  interest_free_36: 2800,
  interest_bearing_12_490: 2800,
  interest_bearing_24_490: 2800,
  interest_bearing_36_490: 2800,
  interest_bearing_48_490: 2800,
  interest_bearing_60_490: 2800,
  interest_bearing_12_790: 2800,
  interest_bearing_24_790: 2800,
  interest_bearing_36_790: 2800,
  interest_bearing_48_790: 2800,
  interest_bearing_60_790: 2800,
  interest_bearing_12_990: 2800,
  interest_bearing_24_990: 2800,
  interest_bearing_36_990: 2800,
  interest_bearing_48_990: 2800,
  interest_bearing_60_990: 2800,
  interest_bearing_12_1190: 4900,
  interest_bearing_24_1190: 4900,
  interest_bearing_36_1190: 4900,
  interest_bearing_48_1190: 4900,
  interest_bearing_60_1190: 4900,
};

export const FINANCE_STATUSES = {
  OFFERED: {
    label: 'Sent',
    color: 'neutral-700',
    highlight: 'neutral-300',
  },
  CREATED: {
    label: 'Submitted',
    color: 'neutral-700',
    highlight: 'neutral-300',
  },
  INITIATED: {
    label: 'Initiated',
    color: 'neutral-700',
    highlight: 'neutral-300',
  },
  INITIALIZED: {
    label: 'Initiated',
    color: 'neutral-700',
    highlight: 'neutral-300',
  },
  'CUSTOMER ERROR': {
    label: 'Customer error',
    color: 'red-200',
    highlight: 'red-100',
  },
  'CREDIT CHECK REFERRED': {
    label: 'Referred',
    color: 'orange-200',
    highlight: 'orange-100',
  },
  'PRE DECLINED': {
    label: 'Pre-declined',
    color: 'red-200',
    highlight: 'red-100',
  },
  'CREDIT CHECK DECLINED': {
    label: 'Declined',
    color: 'red-200',
    highlight: 'red-100',
  },
  'SIGN DOCUMENTS': {
    label: 'Awaiting signed documents',
    color: 'green-500',
    highlight: 'green-200',
  },
  APPROVED: {
    label: 'Awaiting deposit payment',
    color: 'green-500',
    highlight: 'green-200',
  },
  'DEPOSIT PAID': {
    label: 'Deposit paid',
    color: 'green-500',
    highlight: 'green-200',
  },
  'AWAITING FULFILMENT': {
    label: 'Awaiting fulfilment',
    color: 'green-500',
    highlight: 'green-200',
  },
  'ORDER FULFILLED': {
    label: 'Order fulfilled',
    color: 'green-500',
    highlight: 'green-200',
  },
  COMPLETE: {
    label: 'Complete',
    color: 'green-500',
    highlight: 'green-200',
  },
  'APPLICATION LAPSED': {
    label: 'Lapsed',
    color: 'red-200',
    highlight: 'red-100',
  },
  'C.D.S. NOTE REVIEW': {
    label: 'Pending payout',
    color: 'orange-200',
    highlight: 'orange-100',
  },
  'C.D.S. NOTE REQUIRED': {
    label: 'Sat note required',
    color: 'orange-200',
    highlight: 'orange-100',
  },
  'CUSTOMER ACTION REQUIRED': {
    label: 'Customer action required',
    color: 'orange-200',
    highlight: 'orange-100',
  },
  'VERIFY DOCUMENT': {
    label: 'Verify document',
    color: 'orange-200',
    highlight: 'orange-100',
  },
  'ORDER CANCELLED': {
    label: 'Cancelled',
    color: 'red-200',
    highlight: 'red-100',
  },
  'FINANCE OFFER WITHDRAWN': {
    label: 'Withdrawn',
    color: 'red-200',
    highlight: 'red-100',
  },
  'OFFER REFUNDED': {
    label: 'Refunded',
    color: 'red-200',
    highlight: 'red-100',
  },
  'SAT NOTE SENT': {
    label: 'Sat note sent',
    color: 'green-500',
    highlight: 'green-200',
  },
  'SAT NOTE VIEWED': {
    label: 'Sat note viewed',
    color: 'green-500',
    highlight: 'green-200',
  },
  'SAT NOTE COMPLETED': {
    label: 'Sat note signed',
    color: 'green-500',
    highlight: 'green-200',
  },
  'FULFILMENT IN REVIEW': {
    label: 'Fulfilment in review',
    color: 'green-500',
    highlight: 'green-200',
  },
  'ORDER REFUNDED': {
    label: 'Order refunded',
    color: 'red-200',
    highlight: 'red-100',
  },
};
