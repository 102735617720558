import {
  useLoadData,
  useMutate,
  KandaEnterpriseAuthService,
  EnterpriseUserAuthService,
  EnterprisePortalService,
} from '@kanda-api/library';

const ContainerComponent = ({ children }) => {
  const { data: isUserLoggedIn } = useLoadData([
    EnterpriseUserAuthService.key.isUserLoggedIn,
  ]);

  const { data: profile, isLoading } = useLoadData(
    isUserLoggedIn && [EnterprisePortalService.retrieveProfile],
  );

  const { mutate } = useMutate(KandaEnterpriseAuthService.logout);

  const logout = () => mutate();

  return children({ isLoading, logout, name: profile?.enterprise?.name });
};

ContainerComponent.displayName = 'DesktopHeader-container';

export default ContainerComponent;
