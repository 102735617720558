import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { useLoadData, EnterprisePortalService } from '@kanda-api/library';
//
import { formatLoan, sortData, filterData } from './Provider-functions';
import { SEARCH_OPTIONS } from './Provider-constants';

import { useFuse } from '../../../../hooks';

const ContainerComponent = ({ children }) => {
  const [sorting, setSorting] = useState(['updatedAt', 1]);
  const [filter, setFilter] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const { data: profile, isValidating: isLoadingProfile } = useLoadData([
    EnterprisePortalService.retrieveProfile,
  ]);

  const { data: loanData, isValidating: isLoadingApplications } = useLoadData(
    [EnterprisePortalService.retrieveLoanApplications],
    { revalidateOnMount: true },
  );

  const isLoading = isLoadingProfile || !loanData;

  const filterRef = useRef({});

  const loans = useMemo(
    () => (loanData ? loanData?.loanApplications : []),
    [loanData],
  );

  const { hits, query, setQuery } = useFuse(loans || [], SEARCH_OPTIONS, {
    initialQuery: '',
  });

  // This will be returned by pagination in real use case
  const totalPages = isLoadingApplications
    ? 1
    : Math.ceil(hits.length / perPage);

  const setPage = useCallback((index) => setPageIndex(index), []);

  // Data slicing is used in place of API pagination - in real use, use API
  // pagination
  const data = useMemo(() => {
    if (isLoading) return [];
    const rawData = hits.map((user) => formatLoan(user.item));
    return sortData(filterData(rawData, filter), sorting).slice(
      pageIndex * perPage,
      pageIndex * perPage + perPage,
    );
  }, [filter, sorting, isLoading, pageIndex, perPage, hits]);

  const sortingClick = useCallback(
    (sortType) => {
      const sortOrder = sorting[1] === 0 ? 1 : Math.sign(sorting[1]) * -1;
      setSorting([sortType, sortOrder]);
    },
    [sorting],
  );

  const filterClick = useCallback(
    (filterType, filterValue) => {
      const newFilter = {
        ...filter,
        [filterType]: filterValue,
      };
      if (filterValue.length === 0) delete newFilter[filterType];
      setFilter(newFilter);
    },
    [filter],
  );

  /**
   * Handles filter click
   * @param {String} filterType ex: status
   * @param {Array} filterValue
   * @param {Boolean} merge
   */
  const updateFilter = useCallback((filterType, filterValue) => {
    const newFilter = {
      ...filterRef.current,
      [filterType]: filterValue,
    };
    setFilter(newFilter);
    filterRef.current = newFilter;
  }, []);

  const hasActiveFilters = Boolean(filter?.status);

  // Effect resets page index to 0 when hits changes - when user searches
  useEffect(() => {
    setPageIndex(0);
  }, [hits, setPageIndex]);

  return children({
    isLoading,
    isValidating: isLoadingApplications,
    profile,
    data,
    sorting,
    setSorting,
    filter,
    setFilter,
    pageIndex,
    setPageIndex,
    setPage,
    perPage,
    setPerPage,
    totalPages,
    sortingClick,
    filterClick,
    updateFilter,
    hasActiveFilters,
    query,
    setQuery,
  });
};
ContainerComponent.displayName = 'Jobs-Provider-Container';

export default ContainerComponent;
