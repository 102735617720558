import { useLoadData, EnterprisePortalService } from '@kanda-api/library';

import { extractLoanInfo, getSubsidy } from './Subsidy-functions';

const ContainerComponent = ({ children, loan, loanIsLoading }) => {
  const { data: profile, isValidating: profileIsLoading } = useLoadData([
    EnterprisePortalService.retrieveProfile,
  ]);

  const rates = profile?.enterprise?.rates || null;

  const loanInfo = extractLoanInfo(loan?.rate || null);

  const subsidy = getSubsidy(rates, loan?.rate, loan?.price);

  const loanLabel = `${loanInfo.duration} months - ${loanInfo.interest}`;

  const isLoading = loanIsLoading || profileIsLoading;

  const hide = !isLoading && !loan.rate;

  return children({
    hide,
    isLoading,
    subsidy,
    loanLabel,
  });
};

ContainerComponent.displayName = 'LoanDetails-Subsidy-container';

export default ContainerComponent;
