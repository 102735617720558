import { FIELD_NAMES } from './Form-constants';

const extractType = (name) => name.split('.').slice(-1)[0].toLowerCase();

export const createFulfilmentPayload = (files, loan) => ({
  loanApplicationId: loan?.id,
  document: {
    satisfactionNoteType: extractType(
      files[FIELD_NAMES.satNoteFile]?.name || '.txt',
    ),
    quoteCopyType: extractType(files[FIELD_NAMES.quoteFile]?.name || '.txt'),
  },
});
