export const CLASS_NAMES = {
  headerWrapper: 'md:text-center',
  title: 'text-style-b mb-4 text-neutral-900',
  description:
    'text-style-g mb-8 text-neutral-700 whitespace-pre-line md:text-style-f md:text-neutral-600',
  error: 'text-style-h text-red-200 mb-4 -mt-4',
};

export const TITLE = 'Forgot Password';

export const DESCRIPTION =
  "Forgotten your username or password? Just provide us with the email address associated with you account and we'll email you instruction";

export const ERROR = 'Invalid credentials';

export const FIELDS = {
  email: {
    name: 'email',
    label: 'Account email',
    placeholder: 'Your account email',
    autoCapitalize: 'off',
    autoCorrect: 'off',
    autoComplete: 'email',
  },
};
