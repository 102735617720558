const BASE_SKELETON_DATA = {
  link: '#',
};

export const SKELETON_DATA = [
  { key: 'temp-1', ...BASE_SKELETON_DATA },
  { key: 'temp-2', ...BASE_SKELETON_DATA },
  { key: 'temp-3', ...BASE_SKELETON_DATA },
];

export const CLASS_NAMES = {
  container: 'flex flex-col',
  list: 'flex flex-col -mx-6',
  pagination: 'flex flex-row my-4 justify-center',
  link: 'mb-3 border-b border-solid border-neutral-200 last:border-0',
};

export const SKELETONS = {
  content: true,
  option: true,
  title: true,
};

export const CARD_PROPS = {
  padding: 'py-5 px-6',
  variant: 'simple',
};
