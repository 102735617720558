export const CLASS_NAMES = {
  container: 'flex flex-col-reverse md:flex-col w-full mt-6',
  footer: 'flex flex-row justify-center mt-6 mb-6 md:mb-0',
  signup: 'text-style-g text-neutral-700 mr-2 my-auto',
};

export const RESET_PASSWORD_BUTTON = {
  submit: true,
  variant: 'gradient',
  label: 'Request reset',
};

export const LOGIN_BUTTON = {
  label: 'Back to login',
  size: 14,
};
