import React from 'react';

const ViewComponent = () => (
  <div className="py-6 flex flex-col">
    <span className="text-14-22-em text-green-400">Click to select file</span>
    <span className="text-14-22 text-neutral-700">PDF or WORD format</span>
  </div>
);

ViewComponent.displayName = 'LoanDetails-Fulfil-Form-Placeholder-view';

export default ViewComponent;
