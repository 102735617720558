export const CLASS_NAMES = {
  container: 'flex flex-col w-full mt-6',
  footer: 'flex flex-row justify-center mt-6',
  signup: 'text-style-g text-neutral-700 mr-2 my-auto',
};

export const LOGIN_BUTTON = {
  submit: true,
  variant: 'gradient',
  label: 'Login',
};

export const SIGNUP = 'Don’t have an account?';

export const SIGNUP_BUTTON = {
  label: 'Sign up',
  size: 14,
};
