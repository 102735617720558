export const SEARCH_OPTIONS = {
  keys: [
    { name: 'updatedAt', weight: 1 },
    { name: 'customerDetails.firstName', weight: 1 },
    { name: 'customerDetails.lastName', weight: 1 },
    { name: 'customerDetails.email', weight: 1 },
    { name: 'price', weight: 1 },
    { name: 'description', weight: 1 },
    { name: 'status', weight: 1 },
  ],
  includeMatches: true,
  matchAllOnEmptyQuery: true,
  minMatchCharLength: 3,
  threshold: 0.2,
};
