import React from 'react';
import { Field } from '@kanda-form-components/library';

import {
  CLASS_NAMES,
  TITLE,
  DESCRIPTION,
  FIELDS,
} from './Content-constants';

const ViewComponent = () => (
  <>
    <div className={CLASS_NAMES.headerWrapper}>
      <p className={CLASS_NAMES.title}>{TITLE}</p>
      <p className={CLASS_NAMES.description}>{DESCRIPTION}</p>
    </div>
    <Field.PasswordInput
      {...FIELDS.password}
    />
    <Field.PasswordInput
      {...FIELDS.confirmPassword}
    />
  </>
);

ViewComponent.displayName = 'ResetPassword-Content-view';

export default ViewComponent;
