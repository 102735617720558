import { capitalize } from '@kanda-utils/library';

import { FINANCE_STATUSES } from '../../../../../../../../constants';

/**
 * Formats job statuses to filters
 */
export const getJobStatusFilters = () =>
  Object.entries(FINANCE_STATUSES).reduce((values, [value, { label }]) => {
    if (value !== 'INITIALIZED')
      values.push({
        label: capitalize(label),
        value,
      });
    return values;
  }, []);
