import { useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Context from '../Context';
import { ACTIONS } from './Desktop-constants';

const ContainerComponent = ({ children }) => {
  const {
    isLoading,
    isValidating,
    data,
    pageIndex,
    totalPages,
    setPage,
    sortingClick,
    query,
    hasActiveFilters,
  } = useContext(Context);

  const isTableVisible = isLoading || data.length > 0;

  const showNoResults = Boolean(query || hasActiveFilters);

  const history = useHistory();

  /**
   * Handles row click
   * @param {Object} row
   * @param {Object} row.original
   * @param {String} row.original.link
   */
  const onRowClicked = useCallback(
    (row) => {
      history.push(row.original.link);
    },
    [history],
  );

  /**
   * Handles table actions
   * @param {Object} action
   * @param {String} action.type
   * @param {*} action.payload
   */
  const onAction = useCallback(
    (action = {}) => {
      const { type, payload } = action;

      switch (type) {
        case ACTIONS.SORT_BY_CUSTOMER:
          sortingClick(ACTIONS.SORT_BY_CUSTOMER, payload);
          break;

        case ACTIONS.SORT_BY_PRICE:
          sortingClick(ACTIONS.SORT_BY_PRICE, payload);
          break;

        case ACTIONS.SORT_BY_UPDATED_AT:
          sortingClick(ACTIONS.SORT_BY_UPDATED_AT, payload);
          break;

        case ACTIONS.SORT_BY_DESCRIPTION:
          sortingClick(ACTIONS.SORT_BY_DESCRIPTION, payload);
          break;
        default:
      }
    },
    [sortingClick],
  );

  return children({
    isLoading,
    isValidating,
    data,
    pageIndex,
    totalPages,
    setPage,
    onRowClicked,
    onAction,
    isTableVisible,
    showNoResults,
  });
};

ContainerComponent.displayName = 'Job-Desktop-container';

export default ContainerComponent;
