import { useContext } from 'react';

import Context from '../Context';

const ContainerComponent = ({ children }) => {
  const {
    isLoading,
    data,
    pageIndex,
    totalPages,
    setPage,
    query,
    hasActiveFilters,
  } = useContext(Context);

  const isTableVisible = isLoading || data.length > 0;

  const showNoResults = Boolean(query || hasActiveFilters);

  const paginationProps = {
    pageCount: totalPages,
    pageIndex,
    setPage,
  };

  return children({
    isLoading,
    data,
    paginationProps,
    isTableVisible,
    showNoResults,
  });
};

ContainerComponent.displayName = 'Loans-Mobile-container';

export default ContainerComponent;
