import React from 'react';
import PropTypes from 'prop-types';
import { Text, Price } from '@kanda-form-components/library';

import Container from './Subsidy-container';

import { PRICE_PROPS, CLASS_NAMES } from './Subsidy-constants';

const ViewComponent = ({ loan, isLoading: loanIsLoading }) => (
  <Container loan={loan} loanIsLoading={loanIsLoading}>
    {({ hide, isLoading, subsidy, loanLabel }) =>
      !hide && (
        <div className={CLASS_NAMES.container}>
          <div className={CLASS_NAMES.row}>
            <Text
              className={CLASS_NAMES.heading}
              text="Finance Rate Chosen"
              isLoading={isLoading}
            />
            <Text
              isLoading={isLoading}
              text={loanLabel}
              skeletonProps={{
                width: 100,
              }}
              className="text-24-28-em text-green-400"
            />
          </div>
          <div className={CLASS_NAMES.row}>
            <Text
              className={CLASS_NAMES.heading}
              text="Subsidy"
              isLoading={isLoading}
            />
            <Price isLoading={isLoading} amount={subsidy} {...PRICE_PROPS} />
          </div>
        </div>
      )
    }
  </Container>
);

ViewComponent.defaultProps = {
  loan: {},
  isLoading: false,
};

ViewComponent.propTypes = {
  /**
   * Loan object
   */
  loan: PropTypes.object,
  /**
   * Loan is loading
   */
  isLoading: PropTypes.bool,
};

ViewComponent.displayName = 'LoanDetails-Subsidy-view';

export default ViewComponent;
