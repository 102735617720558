import { useState, useCallback } from 'react';
import { useForm, useToast } from '@kanda-form-components/library';
import { useMutate, EnterpriseUploadFileService } from '@kanda-api/library';

import { DEFAULT_VALUES, FIELD_NAMES } from './Form-constants';
import { createFulfilmentPayload } from './Form-functions';

const ContainerComponent = ({
  children,
  handleClose,
  loan,
  fulfilmentRefresh,
}) => {
  const [formError, setFormError] = useState(null);

  const { mutate: upload, isSubmitting } = useMutate(
    EnterpriseUploadFileService.uploadFulfilmentDocs,
  );

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    DEFAULT_VALUES,
  });

  const { showError, showSuccess } = useToast();

  const onSubmit = useCallback(
    (submittedData) => {
      if (
        !submittedData[FIELD_NAMES.quoteFile] ||
        !submittedData[FIELD_NAMES.satNoteFile]
      ) {
        setFormError('Please select both files');
        return;
      }
      if (
        submittedData[FIELD_NAMES.quoteFile].name ===
        submittedData[FIELD_NAMES.satNoteFile].name
      ) {
        setFormError('Please select two different files');
        return;
      }
      setFormError(null);
      const payload = createFulfilmentPayload(submittedData, loan);
      upload(submittedData, payload).then(({ error: uploadError }) => {
        if (uploadError) {
          showError(
            'There has been an issue uploading your documents at this time',
          );
          handleClose();
          return;
        }
        fulfilmentRefresh();
        showSuccess('Your documents have been uploaded');
        handleClose();
      });
    },
    [handleClose, loan, fulfilmentRefresh, showError, showSuccess, upload],
  );

  return children({
    form,
    onSubmit,
    formError,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'LoanDetails-Buttons-Fulfil-container';

export default ContainerComponent;
