import React from 'react';
import { Modal } from '@kanda-form-components/library';

import { MODAL_ID } from '../SendSatNote-constants';
import { Form } from '../components';

const ViewComponent = (props) => (
  <Modal.Container opacity={50} id={MODAL_ID}>
    {({ handleClose }) => (
      <Modal.Layout.Center onClose={handleClose}>
        <Form handleClose={handleClose} {...props} />
      </Modal.Layout.Center>
    )}
  </Modal.Container>
);

ViewComponent.displayName = 'SendSatNoteModal-Desktop-view';

export default ViewComponent;
