export const BUTTON = {
  variant: 'ghost-grey',
  size: '40-20',
  icon: 'filter',
  className: 'mr-px',
};

export const BUTTON_INDICATOR = {
  ...BUTTON,
  indicator: 'turquoise-400',
};
