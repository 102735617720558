import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@kanda-form-components/library';

import Container from './Button-container';

const ViewComponent = ({ buttonId, isLoading, ...restProps }) => (
  <Container {...restProps}>
    {({ buttonProps, handleClick }) => (
      <Button.Icon
        isLoading={isLoading}
        id={buttonId}
        onClick={handleClick}
        {...buttonProps}
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Jobs-Filter-Button-View';

ViewComponent.defaultProps = {
  isLoading: undefined,
};

ViewComponent.propTypes = {
  /**
   * ID for the filter modal
   */
  modalId: PropTypes.string.isRequired,
  /**
   * ID for the filter button
   */
  buttonId: PropTypes.string.isRequired,
  /**
   * ID for the filter button
   */
  isLoading: PropTypes.bool,
};

export default ViewComponent;
