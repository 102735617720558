export const TAG = {
  size: 31,
  variant: 'solid',
  className: 'inline-flex mr-2 mb-2',
};

export const BUTTON = {
  variant: 'custom',
  className: 'ml-2',
  size: 12,
  iconProps: {
    size: 12,
  },
  icon: 'close',
};
