const ContainerComponent = ({ children, customerDetails }) => {
  const name = customerDetails?.firstName
    ? `${customerDetails?.firstName} ${customerDetails?.lastName}`
    : '- -';

  return children({
    name,
  });
};

ContainerComponent.displayName = 'Title-container';

export default ContainerComponent;
