import React from 'react';
import { PlaceHolder } from '@kanda-form-components/library';

import { Title, Button } from './components';

const ViewComponent = () => (
  <div className="flex flex-col flex-1 mt-8">
    <PlaceHolder bg="neutral-50" title={<Title />} cta={<Button />} />
  </div>
);

ViewComponent.displayName = 'NoResults-view';

export default ViewComponent;
