import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Pagination } from '@kanda-form-components/library';

import Container from './MobileList-container';
import { CLASS_NAMES, SKELETONS, CARD_PROPS } from './MobileList-constants';
import { Activity, Title } from '..';

const ViewComponent = ({ isLoading, data: rawData, paginationProps }) => (
  <Container isLoading={isLoading} rawData={rawData}>
    {({ data }) => (
      <div className={CLASS_NAMES.container}>
        <div className={CLASS_NAMES.list}>
          {data.map(
            ({
              link,
              key,
              customerDetails,
              status,
              price,
              updatedAt,
              description,
            }) => (
              <Link className={CLASS_NAMES.link} to={link} key={key}>
                <Card
                  {...CARD_PROPS}
                  isLoading={isLoading}
                  showLoadingSkeleton={SKELETONS}
                  title={
                    <Title customerDetails={customerDetails} amount={price} />
                  }
                  option={<Activity timestamp={updatedAt} status={status} />}
                >
                  {description}
                </Card>
              </Link>
            ),
          )}
        </div>
        {!isLoading && (
          <div className={CLASS_NAMES.pagination}>
            <Pagination {...paginationProps} />
          </div>
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'MobileList-view';

ViewComponent.defaultProps = {
  isLoading: false,
  data: undefined,
  paginationProps: undefined,
};

ViewComponent.propTypes = {
  /**
   * Jobs data is loading state
   */
  isLoading: PropTypes.bool,
  /**
   * Jobs data
   */
  data: PropTypes.arrayOf(PropTypes.object),
  /**
   * Props for pagination
   */
  paginationProps: PropTypes.shape({
    pageCount: PropTypes.number,
    pageIndex: PropTypes.number,
    setPage: PropTypes.func,
  }),
};

export default ViewComponent;
