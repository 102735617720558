import React from 'react';
import { Modal, Header, Button } from '@kanda-form-components/library';

import { MODAL_ID } from '../Fulfil-constants';
import { Form } from '../components';

const ViewComponent = (props) => (
  <Modal.Container id={MODAL_ID}>
    {({ handleClose }) => (
      <Modal.Layout.FullScreen
        header={
          <Header.Base
            className="text-style-f-em text-neutral-700"
            help
            options={[
              <Button.Icon key="close" onClick={handleClose} icon="close" />,
            ]}
          >
            Fulfil order
          </Header.Base>
        }
        onClose={handleClose}
      >
        <Form handleClose={handleClose} {...props} />
      </Modal.Layout.FullScreen>
    )}
  </Modal.Container>
);

ViewComponent.displayName = 'FulfilModal-Mobile-view';

export default ViewComponent;
