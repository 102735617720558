import { urlWithParams } from '@kanda-utils/library';

import { URLS } from '../../../../config';

// const sortId = (rawData, sortOrder) => {
//   const sorted = rawData.sort((row1, row2) =>
//     row1.id.localeCompare(row2.id),
//   );
//   if (sortOrder === 1) return sorted;
//   return sorted.reverse();
// };

const sortUpdatedAt = (rawData, sortOrder) => {
  const sorted = rawData.sort((row1, row2) => row2.updatedAt - row1.updatedAt);
  if (sortOrder === 1) return sorted;
  return sorted.reverse();
};

// const sortName = (rawData, sortOrder) => {
//   const sorted = rawData.sort((row1, row2) =>
//     row2.name.localeCompare(row1.name),
//   );
//   if (sortOrder === 1) return sorted;
//   return sorted.reverse();
// };

const sortAlphabetical = (rawData, key, sortOrder) => {
  const sorted = rawData.sort((row1, row2) =>
    row2[key].localeCompare(row1[key]),
  );
  if (sortOrder === 1) return sorted;
  return sorted.reverse();
};

const sortCustomer = (rawData, key, sortOrder) => {
  const sorted = rawData.sort((row1, row2) => {
    const name1 = row1.customerDetails[key] || '- -';
    const name2 = row2.customerDetails[key] || '- -';
    return name2.localeCompare(name1);
  });
  if (sortOrder === 1) return sorted;
  return sorted.reverse();
};

const sortPrice = (rawData, sortOrder) => {
  const sorted = rawData.sort((row1, row2) => row2.price - row1.price);
  if (sortOrder === 1) return sorted;
  return sorted.reverse();
};

export const sortData = (rawData, sorting) => {
  if (sorting[1] === 0) return rawData;
  if (sorting[0] === 'updatedAt') return sortUpdatedAt(rawData, sorting[1]);
  if (sorting[0] === 'description')
    return sortAlphabetical(rawData, 'description', sorting[1]);
  if (sorting[0] === 'customer')
    return sortCustomer(rawData, 'lastName', sorting[1]);
  if (sorting[0] === 'price') return sortPrice(rawData, sorting[1]);
  return rawData;
};

export const filterData = (rawData, filter) => {
  let filteredData = [...rawData];

  if (filter.status) {
    filteredData = filteredData.filter((data) =>
      data.status.includes(filter.status),
    );
  }

  return filteredData;
};

/**
 * Formats job
 * @param {Object} job
 */
export const formatLoan = (loan) => {
  const link = urlWithParams(URLS.LOAN_DETAILS, { id: loan.id });

  const key = loan.id;

  return {
    ...loan,
    link,
    key,
  };
};
