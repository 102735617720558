import React from 'react';
import PropTypes from 'prop-types';

const ViewComponent = ({ text }) => (
  <p className="text-14-22-em mb-2 text-neutral-900">{text}</p>
);

ViewComponent.defaultProps = {
  text: '',
};

ViewComponent.propTypes = {
  /**
   * Label text
   */
  text: PropTypes.string,
};

ViewComponent.displayName = 'LoanDetails-Fulfil-Form-Label-view';

export default ViewComponent;
