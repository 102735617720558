import { useCallback, useContext } from 'react';
import { ModalsWrapper } from '@kanda-form-components/library';

import { BUTTON_INDICATOR, BUTTON } from './Button-constants';

import Context from '../../../../../../Context';

const ContainerComponent = ({ children, modalId, onClick }) => {
  const { showModal } = useContext(ModalsWrapper.Context);
  const { filters } = useContext(Context);

  const showNotification = filters?.status;

  const buttonProps = showNotification ? BUTTON_INDICATOR : BUTTON;

  /**
   * Function to show delete item modal
   */
  const handleShowModal = useCallback(
    () => showModal(modalId),
    [showModal, modalId],
  );

  const handleClick = onClick || handleShowModal;

  return children({
    buttonProps,
    handleShowModal,
    handleClick,
  });
};

ContainerComponent.displayName = 'Jobs-Filter-Button-Container';

export default ContainerComponent;
