export const PRICE_PROPS = {
  poundsClassName: 'text-24-28-em',
  centsClassName: 'text-24-28-em',
};

export const CLASS_NAMES = {
  container: 'flex flex-row mt-6',
  row: 'flex flex-col first: ml-6 last:ml-10',
  heading: 'text-16-24 text-green-400',
};
