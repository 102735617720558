import React from 'react';
import { Menu, SkeletonLoader } from '@kanda-form-components/library';
import { Link } from 'react-router-dom';

import { MENU_LINKS, SKELETONS } from './Menu-constants';
import Container from './Menu-container';

const ViewComponent = () => (
  <Container>
    {({ isLoading, name }) => (
      <Menu
        linkComponent={Link}
        items={MENU_LINKS}
        companyName={
          <SkeletonLoader
            isLoading={isLoading}
            {...SKELETONS.companyName}
            afterLoading={name}
          />
        }
        companyProfilePicture={null}
      />
    )}
  </Container>
);

ViewComponent.displayName = 'Menu-view';

export default ViewComponent;
