import { FIELD_NAMES } from './Form-constants';

const extractType = (name) => name.split('.').slice(-1)[0].toLowerCase();

export const createSatNotePayload = (files, loan) => ({
  loanApplicationId: loan?.id,
  document: {
    satisfactionNoteType: 'txt',
    quoteCopyType: extractType(files[FIELD_NAMES.quoteFile]?.name || '.txt'),
  },
});
