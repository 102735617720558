import { PDFDocument } from 'pdf-lib';

import { PDF_BASE64 } from './DownloadSatNote-constants';

/**
 * Function to generate byte array from PDF base64 string
 */
const createPdfByteArray = () => {
  const binaryString = atob(PDF_BASE64);
  const { length } = binaryString;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

/**
 * Formats loan and profile info for download
 *
 * @param {Object} loan The object containing application data
 * @param {Object} profile The object containing organization data
 * @param {Function} setPdf Function to set the PDF into the state
 */
export const formatLoanInfo = (loan, profile) => {
  const address = `${
    loan?.customerDetails?.currentAddress?.houseNumber ||
    loan?.customerDetails?.currentAddress?.houseName ||
    loan?.customerDetails?.currentAddress?.flat
  } ${loan?.customerDetails?.currentAddress?.street}`;

  return {
    customer: {
      name: `${loan?.customerDetails?.firstName} ${loan?.customerDetails?.lastName}`,
      address,
      city: loan?.customerDetails?.currentAddress?.city,
      postcode: loan?.customerDetails?.currentAddress?.postCode,
    },
    fullReference: `${profile?.enterprise?.enterpriseId}/${loan?.reference}`,
    description: loan?.description || '',
    organizationName: profile?.enterprise?.name || '',
  };
};

/**
 * Function to add customer details into satisfaction note PDF
 *
 * @param {Object} loan The object containing application data
 * @param {Object} profile The object containing organization data
 * @param {Function} setPdf Function to set the PDF into the state
 */
export const fillForm = async (loan, profile, setPdf) => {
  // Create byte array
  const formPdfBytes = createPdfByteArray();
  // Extract ifno
  const loanInfo = formatLoanInfo(loan, profile);

  // Load PDF into PDFDocument
  const pdfDoc = await PDFDocument.load(formPdfBytes);

  // Extract from
  const form = pdfDoc.getForm();

  // Get fields
  const referenceField = form.getTextField('reference');
  const descriptionField = form.getTextField('description_of_goods');
  const nameField = form.getTextField('customer_name');
  const addressField = form.getTextField('customer_address');

  // Update fields
  referenceField.setText(loanInfo.fullReference);
  descriptionField.setText(
    `${loanInfo.description} - ${loanInfo.organizationName}`,
  );
  nameField.setText(loanInfo.customer.name);
  addressField.setText(
    [
      loanInfo.customer.address,
      loanInfo.customer.city,
      loanInfo.customer.postcode,
    ].join('\n'),
  );

  // Update metadata
  pdfDoc.setTitle(`Satisfaction Note - ${loanInfo.fullReference}`);
  pdfDoc.setAuthor('Kanda Products & Services Ltd.');
  pdfDoc.setSubject('Satisfaction Note');
  pdfDoc.setCreator('Kanda Products & Services Ltd.');
  pdfDoc.setKeywords(['satisfaction', 'note']);
  pdfDoc.setProducer('Kanda Products & Services Ltd.');

  // Create bytes array for download
  const pdfBytes = await pdfDoc.save();

  // set the PDF
  setPdf(pdfBytes);
};
