import { useEffect, useRef, useState, useMemo } from 'react';
import { useLoadData, EnterprisePortalService } from '@kanda-api/library';

const getOffset = () => {
  const now = new Date().getTime();
  const lock = new Date('2022-08-08').getTime();
  return lock - now > 0;
};

const useLockout = () => {
  const [offset, setOffset] = useState(getOffset());

  const { data: profile } = useLoadData(
    EnterprisePortalService.retrieveProfile,
  );

  const timerRef = useRef(null);

  useEffect(() => {
    if (!offset) clearTimeout(timerRef.current);
  }, [offset]);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setOffset(getOffset());
    }, 5000);
  }, []);

  const bypass = useMemo(() => {
    if (!profile) return false;
    if (['99998', '99999'].includes(profile?.enterprise?.enterpriseId))
      return true;
    return false;
  }, [profile]);

  return {
    offset,
    bypass,
  };
};

export default useLockout;
