import React from 'react';
import PropTypes from 'prop-types';
import { Price } from '@kanda-form-components/library';

import Container from './Title-container';
import { CLASS_NAMES } from './Title-constants';

const ViewComponent = ({ customerDetails, amount }) => (
  <Container customerDetails={customerDetails}>
    {({ name }) => (
      <div className={CLASS_NAMES.container}>
        <span className={CLASS_NAMES.title}>{name}</span>
        <Price
          amount={amount}
          poundsClassName={CLASS_NAMES.pounds}
          centsClassName={CLASS_NAMES.cents}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Title-view';

ViewComponent.defaultProps = {
  customerDetails: undefined,
  amount: undefined,
};

ViewComponent.propTypes = {
  /**
   * Job name
   */
  customerDetails: PropTypes.object,
  /**
   * Job amount
   */
  amount: PropTypes.number,
};

export default ViewComponent;
