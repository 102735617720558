import { useCallback, useContext } from 'react';
import { ModalsWrapper } from '@kanda-form-components/library';

import { MODAL_ID } from './LockOutBanner-constants';

import useLockout from '../../../../../../hooks/useLockout';

const ContainerComponent = ({ children }) => {
  const { offset, bypass } = useLockout();

  const { showModal } = useContext(ModalsWrapper.Context);

  const showTemporary = offset && !bypass;
  const showLocked = !offset && !bypass;

  /**
   * Function to show delete item modal
   */
  const handleShowModal = useCallback(() => showModal(MODAL_ID), [showModal]);

  return children({
    showTemporary,
    showLocked,
    handleShowModal,
  });
};

ContainerComponent.displayName = 'LockOutBanner-container';

export default ContainerComponent;
