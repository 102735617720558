import { useCallback, useContext } from 'react';
import { ModalsWrapper } from '@kanda-form-components/library';

import { MODAL_ID } from './Fulfil-constants';

const ContainerComponent = ({ children }) => {
  const { showModal } = useContext(ModalsWrapper.Context);

  /**
   * Function to show delete item modal
   */
  const handleShowModal = useCallback(() => showModal(MODAL_ID), [showModal]);

  return children({
    onClick: handleShowModal,
  });
};

ContainerComponent.displayName = 'LoanDetails-Buttons-Fulfil-container';

export default ContainerComponent;
