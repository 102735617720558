export const CLASS_NAMES = {
  content: 'flex flex-col items-center p-10',
  title: 'text-style-b text-neutral-700 mb-8',
  description: 'text-style-f text-neutral-600 mb-8 w-80',
};

export const ICON_PROPS = {
  className: 'mb-8',
  stroke: 'turquoise-400',
  icon: 'job',
  size: 48,
};

export const TITLE = 'Get started by creating your first job';

export const BUTTON_PROPS = {
  label: 'Create a new Job',
  left: true,
  icon: 'plus',
  size: 40,
};
