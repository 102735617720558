import { useContext } from 'react';

import Context from '../../Context';

import useLockout from '../../../../hooks/useLockout';

const ContainerComponent = ({ children }) => {
  const {
    isLoading,
    data: jobs,
    hasActiveFilters: showActiveFilters,
    query,
  } = useContext(Context);

  const { offset, bypass } = useLockout();

  const showOptions =
    isLoading || jobs.length > 0 || showActiveFilters || query;

  // TODO: show when data is ready on BE
  const showInfo = false && showOptions && !showActiveFilters && !query;

  return children({
    isLoading,
    jobs,
    showOptions,
    showActiveFilters,
    showInfo,
    showButton: offset || bypass,
  });
};

ContainerComponent.displayName = 'Banner-container';

export default ContainerComponent;
