import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Header } from '@kanda-form-components/library';

import { Content, Footer } from '../components';

const ViewComponent = ({ disabled, error }) => (
  <Layout
    noBorder
    header={<Header.Base lines />}
    footer={<Footer disabled={disabled} />}
  >
    <Content error={error} />
  </Layout>
);

ViewComponent.defaultProps = {
  disabled: undefined,
  error: undefined,
};

ViewComponent.displayName = 'ForgotPassword-Mobile-view';

ViewComponent.propTypes = {
  /**
   * Disabled
   */
  disabled: PropTypes.bool,
  /**
   * Disabled
   */
  error: PropTypes.object,
};

export default ViewComponent;
