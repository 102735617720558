const ContainerComponent = ({ children, loan }) => {
  const text = `Ref ${loan?.reference}`;

  return children({
    text,
  });
};

ContainerComponent.displayName = 'LoanDetails-Reference-container';

export default ContainerComponent;
