import { useContext, useCallback } from 'react';

import Context from '../../../../../../Context';

const ContainerComponent = ({ children }) => {
  const { updateFilter } = useContext(Context);

  const onClick = useCallback(
    () => updateFilter('status', undefined),
    [updateFilter],
  );

  return children({ onClick });
};

ContainerComponent.displayName = 'StatusFilter-container';

export default ContainerComponent;
