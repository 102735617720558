import { useState, useCallback } from 'react';
import { useForm, useToast } from '@kanda-form-components/library';
import { useMutate, EnterpriseUploadFileService } from '@kanda-api/library';

import { DEFAULT_VALUES, FIELD_NAMES } from './Form-constants';
import { createSatNotePayload } from './Form-functions';

const ContainerComponent = ({
  children,
  handleClose,
  loan,
  satNoteSentRefresh,
}) => {
  const [formError, setFormError] = useState(null);

  const { mutate: upload, isSubmitting } = useMutate(
    EnterpriseUploadFileService.sendSatNote,
  );

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    DEFAULT_VALUES,
  });

  const { showError, showSuccess } = useToast();

  const onSubmit = useCallback(
    (submittedData) => {
      if (!submittedData[FIELD_NAMES.quoteFile]) {
        setFormError('Please select a file');
        return;
      }
      setFormError(null);
      const payload = createSatNotePayload(submittedData, loan);
      upload(submittedData, payload).then(({ error: uploadError }) => {
        if (uploadError) {
          showError(
            'There has been an issue uploading your documents at this time',
          );
          handleClose();
          return;
        }
        satNoteSentRefresh();

        showSuccess(
          'Your documents have been uploaded and a satisfaction note sent to your customer',
        );
        handleClose();
      });
    },
    [handleClose, loan, satNoteSentRefresh, showError, showSuccess, upload],
  );

  return children({
    form,
    onSubmit,
    formError,
    isSubmitting,
  });
};

ContainerComponent.displayName = 'LoanDetails-Buttons-SendSatNote-container';

export default ContainerComponent;
