import React from 'react';
import { Icon, Text, Button } from '@kanda-form-components/library';

import Container from './Content-container';
import { LINE1, LINE2 } from './Content-constants';

const ViewComponent = () => (
  <Container>
    {({ onClick }) => (
      <div className="flex flex-col my-auto">
        <Icon icon="send-quote-color" size={104} className="mx-auto mb-6" />
        <Text text={LINE1} className="text-14-22 mb-2" />
        <Text text={LINE2} className="text-14-22 mb-6" />
        <Button.Text
          onClick={onClick}
          label="Contact Kanda"
          variant="gradient"
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'LockOutBannerer-Modal-Content-view';

export default ViewComponent;
