import React from 'react';

import { TEXT } from './Title-constants';

const ViewComponent = () => (
  <div className="flex flex-col mx-auto text-16-20-em">
    <span className="mb-1">{TEXT.heading}</span>
    <span>{TEXT.subHeading}</span>
  </div>
);

ViewComponent.displayName = 'JobsPlaceholder-Title-view';

export default ViewComponent;
