import { formatDate, checkAndFormatTimestamp } from '@kanda-utils/library';

const ContainerComponent = ({ children, loan }) => {
  const date = formatDate(checkAndFormatTimestamp(loan?.createdAt));

  const text = `Created on ${date}`;

  return children({
    text,
  });
};

ContainerComponent.displayName = 'LoanDetails-CreatedAt-container';

export default ContainerComponent;
