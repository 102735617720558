import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@kanda-form-components/library';

import Container from './Reference-container';
import { SKELETON_PROPS } from './Reference-constants';

const ViewComponent = ({ loan, isLoading }) => (
  <Container loan={loan}>
    {({ text }) => (
      <Text
        className="text-14-22 lg:text-16-24"
        isLoading={isLoading}
        text={text}
        skeletonProps={SKELETON_PROPS}
      />
    )}
  </Container>
);

ViewComponent.defaultProps = {
  loan: {},
  isLoading: false,
};

ViewComponent.propTypes = {
  /**
   * Loan object
   */
  loan: PropTypes.object,
  /**
   * Loan is loading
   */
  isLoading: PropTypes.bool,
};

ViewComponent.displayName = 'LoanDetails-Reference-view';

export default ViewComponent;
