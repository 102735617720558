import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon } from '@kanda-form-components/library';

import Container from './Base-container';

const ViewComponent = ({ onClick, text, icon, ...rest }) => (
  <Container {...rest}>
    {({ classNames, iconStroke }) => (
      <button type="button" className={classNames.button} onClick={onClick}>
        <Text text={text} className={classNames.text} />
        <Icon
          icon={icon}
          size={20}
          stroke={iconStroke}
          className={classNames.icon}
        />
      </button>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  onClick: undefined,
  text: undefined,
  icon: undefined,
};

ViewComponent.propTypes = {
  /**
   * onClick function
   */
  onClick: PropTypes.func,
  /**
   * Text for button
   */
  text: PropTypes.string,
  /**
   * Icon name
   */
  icon: PropTypes.string,
};

ViewComponent.displayName = 'LoanDetails-Buttons-BaseButton-view';

export default ViewComponent;
