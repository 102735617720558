import { useContext, useMemo } from 'react';

import { DEFAULT_FILTERS, CLASS_NAMES } from './Options-constants';
import { getJobStatusFilters } from './Options-function';

import Context from '../../../../../../Context';

const ContainerComponent = ({ children, handleClose }) => {
  const { filter, updateFilter } = useContext(Context);

  const statusFilter = filter?.status;

  const filterValues = useMemo(
    () => [...DEFAULT_FILTERS, ...getJobStatusFilters()],
    [],
  );

  const options = filterValues.map(({ label, value }) => {
    const statusClassName =
      statusFilter === value ? CLASS_NAMES.active : CLASS_NAMES.inactive;

    const className = `${CLASS_NAMES.base} ${statusClassName}`;

    const onClick = () => {
      handleClose?.();
      updateFilter('status', value);
    };

    return {
      label,
      value,
      onClick,
      className,
    };
  });

  return children({ options });
};

ContainerComponent.displayName = 'Options-container';

export default ContainerComponent;
