const ContainerComponent = ({ children, loan }) => {
  const text = loan?.description || '';

  return children({
    text,
  });
};

ContainerComponent.displayName = 'LoanDetails-Description-container';

export default ContainerComponent;
