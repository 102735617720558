import { Button } from '@kanda-form-components/library';
import React from 'react';

import { FILTER_TYPE, BUTTON_PROPS } from './ActiveFilters-constants';
import Container from './ActiveFilters-container';
import { StatusFilter } from './components';

const ViewComponent = () => (
  <Container>
    {({ activeFilters, showClearAllFilters, onClearAll }) => (
      <div className="flex flex-row pb-2 flex-wrap -mb-2 items-center">
        {activeFilters.map(({ type, ...props }) => {
          switch (type) {
            case FILTER_TYPE.STATUS:
              return <StatusFilter key={type} {...props} />;
            default:
              return <React.Fragment key={type} />;
          }
        })}
        {showClearAllFilters && (
          <Button.Text {...BUTTON_PROPS} onClick={onClearAll} />
        )}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'ActiveFilters-view';

export default ViewComponent;
