export const CLASS_NAMES = {
  container:
    'flex flex-row items-center py-1 px-3 border border-neutral-200 rounded-full mr-2 mb-2',
  info: 'flex flex-col mr-5 ml-2',
  title: 'text-style-k-em text-neutral-500',
  label: 'text-style-h-em text-neutral-600 -mt-0.5',
};

export const BUTTON = {
  variant: 'custom',
  className: 'ml-2 text-neutral-500',
  size: 12,
  iconProps: {
    size: 12,
  },
  icon: 'close',
};

export const ICON_PROPS = {
  size: 16,
  stroke: 'neutral-500',
};
