import React from 'react';
import { BreakPoints } from '@kanda-form-components/library';

import { Provider } from './Context';
import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = () => (
  <Provider>
    <BreakPoints mobile={<Mobile />} desktop={<Desktop />} />
  </Provider>
);

ViewComponent.displayName = 'Home-view';

export default ViewComponent;
