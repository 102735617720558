import { Price } from '@kanda-form-components/library';

import { Customer, Activity } from './components';

export const DEFAULT_COLUMN = {
  minWidth: 80,
  width: 180,
  maxWidth: 400,
};

export const ACTIONS = {
  SORT_BY_CUSTOMER: 'customer',
  SORT_BY_PRICE: 'price',
  SORT_BY_DESCRIPTION: 'description',
  SORT_BY_UPDATED_AT: 'updatedAt',
};

export const TABLE_COLUMNS = [
  {
    Header: 'Customer',
    accessor: 'customerDetails',
    items: [
      {
        action: {
          type: ACTIONS.SORT_BY_CUSTOMER,
        },
        icon: 'alphabet',
        name: 'Sort by customer',
      },
    ],
    renderComponent: {
      component: Customer,
      value: 'customer',
    },
  },
  {
    Header: 'Price',
    accessor: 'price',
    items: [
      {
        action: {
          type: ACTIONS.SORT_BY_PRICE,
        },
        icon: 'pound',
        name: 'Sort by price',
      },
    ],
    renderComponent: {
      component: Price,
      value: 'amount',
      props: {
        color: 'neutral-900',
        poundsClassName: 'text-14-22-em',
        centsClassName: 'text-14-22-em',
      },
    },
  },
  {
    Header: 'Job Title',
    accessor: 'description',
    items: [
      {
        action: {
          type: ACTIONS.SORT_BY_DESCRIPTION,
        },
        icon: 'alphabet',
        name: 'Sort by description',
      },
    ],
  },
  {
    Header: 'Last Activity',
    accessor: 'updatedAt',
    subAccessors: ['status'],
    items: [
      {
        action: {
          type: ACTIONS.SORT_BY_UPDATED_AT,
        },
        icon: 'archive',
        name: 'Sort by last update',
      },
    ],
    renderComponent: {
      component: Activity,
      value: 'timestamp',
      optionalProps: ['status'],
    },
  },
];
