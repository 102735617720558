import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Button } from '@kanda-form-components/library';

import { TAG, BUTTON } from './Mobile-constants';

const ViewComponent = ({ label, onClick }) => (
  <Tag {...TAG} label={label}>
    <Button.Icon {...BUTTON} onClick={onClick} />
  </Tag>
);

ViewComponent.displayName = 'Filter-Mobile-view';

ViewComponent.propTypes = {
  /**
   * Label
   */
  label: PropTypes.string.isRequired,
  /**
   * On click function
   */
  onClick: PropTypes.func.isRequired,
};

export default ViewComponent;
