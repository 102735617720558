import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field, Button, Loader } from '@kanda-form-components/library';

import Container from './Form-container';
import { FIELDS, BUTTON_PROPS } from './Form-constants';
import { Placeholder, Label } from './components';

const ViewComponent = ({ handleClose, ...restProps }) => (
  <Container handleClose={handleClose} {...restProps}>
    {({ form, onSubmit, formError, isSubmitting }) => (
      <Form form={form} onSubmit={onSubmit} className="flex flex-1">
        <div className="flex flex-col flex-1 -mb-6 md:mb-0 md:w-100">
          <h1 className="hidden md:block text-20-24-em mb-8">
            Upload your documents
          </h1>
          <h2 className="text-16-24 text-neutral-700 mb-8">
            Upload a copy of your quote and Kanda will send your customer a
            satisfaction note to sign.
          </h2>
          <Field.FileInput
            {...FIELDS.quote}
            placeholder={<Placeholder />}
            centerPlaceholder
            label={<Label text="A copy of order form / quote" />}
          />
          {formError && (
            <span className="text-style-h text-red-200 mx-auto">
              {formError}
            </span>
          )}
          <div className="flex flex-row justify-center mt-auto md:mt-6">
            <Button.Text
              {...BUTTON_PROPS.cancel}
              disabled={isSubmitting}
              onClick={handleClose}
            />
            <Button.Text {...BUTTON_PROPS.submit} disabled={isSubmitting} />
          </div>
        </div>
        <Loader isLoading={isSubmitting} />
      </Form>
    )}
  </Container>
);

ViewComponent.defaultProps = {
  handleClose: undefined,
};

ViewComponent.propTypes = {
  /**
   * Label text
   */
  handleClose: PropTypes.func,
};

ViewComponent.displayName = 'LoanDetails-SendSatNote-Form-view';

export default ViewComponent;
