import React from 'react';
import PropTypes from 'prop-types';

import Container from './Options-container';
import { CLASS_NAMES } from './Options-constants';

const ViewComponent = ({ handleClose }) => (
  <Container handleClose={handleClose}>
    {({ options }) => (
      <div className={CLASS_NAMES.container}>
        {options.map((optionProps) => (
          <button type="button" key={optionProps.label} {...optionProps}>
            {optionProps.label}
          </button>
        ))}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Jobs-Filter-Modal-View';

ViewComponent.propTypes = {
  /**
   * Handle close function
   */
  handleClose: PropTypes.func.isRequired,
};

export default ViewComponent;
