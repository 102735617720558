import React from 'react';
import PropTypes from 'prop-types';

import Container from './BackLink-container';

const ViewComponent = ({ children }) => (
  <Container>{({ handleClick }) => children({ handleClick })}</Container>
);

ViewComponent.displayName = 'BackLink';

ViewComponent.defaultProps = {
  children: null,
};

ViewComponent.propTypes = {
  /**
   * Children
   */
  children: PropTypes.func,
};

export default ViewComponent;
