import PropTypes from 'prop-types';
import React from 'react';

import Container from './StatusFilter-container';
import { FILTER_TAGS } from './StatusFilter-constants';

import Filter from '../Filter';

const ViewComponent = ({ label }) => (
  <Container>
    {({ onClick }) => (
      <Filter {...FILTER_TAGS} label={label} onClick={onClick} />
    )}
  </Container>
);

ViewComponent.displayName = 'StatusFilter-view';

ViewComponent.propTypes = {
  /**
   * Label
   */
  label: PropTypes.string.isRequired,
};

export default ViewComponent;
