export const CLASS_NAMES = {
  content: 'flex flex-col items-center py-16',
  title: 'text-style-b text-neutral-700 mb-6',
  description: 'text-style-f text-center text-neutral-600 w-80 mb-8',
};

export const ICON_PROPS = {
  className: 'mb-8',
  stroke: 'turquoise-400',
  icon: 'search-file',
  size: 64,
};

export const TITLE = 'No results';

export const DESCRIPTION =
  'Try searching with another keyword, or send a new Job';

export const BUTTON_PROPS = {
  label: 'Create a new Job',
  left: true,
  icon: 'plus',
  size: 40,
};
