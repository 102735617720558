import { FINANCE_STATUSES } from '../../../../../constants';

const ContainerComponent = ({ children, timestamp, status }) => {
  const financeStatus = FINANCE_STATUSES[status.replace(/_/g, ' ')];

  const { label, color } = financeStatus || FINANCE_STATUSES.OFFERED;

  const textClassName = `text-14-22 text-${color}`;

  const classNames = {
    label: `${textClassName} whitespace-normal text-center mr-1`,
    time: textClassName,
  };

  return children({
    label,
    timestamp,
    classNames,
  });
};

ContainerComponent.displayName = 'Activity-container';

export default ContainerComponent;
