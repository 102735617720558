import { useMemo, useCallback } from 'react';
import { useHelpcrunch } from '@kanda-form-components/library';
import { useLoadData, EnterprisePortalService } from '@kanda-api/library';

import { MESSAGE } from './Content-constants';

const ContainerComponent = ({ children }) => {
  const { showNewMessage, openChat } = useHelpcrunch();

  const { data: profile } = useLoadData([
    EnterprisePortalService.retrieveProfile,
  ]);

  const message = useMemo(() => {
    const id = profile?.enterprise?.enterpriseId || 'unknown';
    return MESSAGE.replace('$id', id);
  }, [profile]);

  const onClick = useCallback(() => {
    showNewMessage(message);
    openChat();
  }, [message, openChat, showNewMessage]);

  return children({
    onClick,
  });
};

ContainerComponent.displayName = 'LockOutBannerer-Modal-Content-container';

export default ContainerComponent;
