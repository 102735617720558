import React from 'react';
import { DesktopHeader } from '@kanda-form-components/library';

import Container from './DesktopHeader-container';

const ViewComponent = () => (
  <Container>
    {({ isLoading, name, logout }) => (
      <div className="w-full flex flex-1 z-50">
        <DesktopHeader
          isLoading={isLoading}
          logout={logout}
          help
          companyName={name}
        />
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'DesktopHeader-view';

export default ViewComponent;
