import React from 'react';
import { Text } from '@kanda-form-components/library';

import Container from './Customer-container';

const ViewComponent = ({ ...props }) => (
  <Container {...props}>
    {({ name }) => (
      <div className="flex flex-col">
        <Text text={name} className="text-16-20-em text-neutral-900" />
      </div>
    )}
  </Container>
);
ViewComponent.displayName = 'Customer-view';

export default ViewComponent;
