import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@kanda-form-components/library';
import { Link } from 'react-router-dom';

import {
  RESET_PASSWORD_BUTTON,
  LOGIN_BUTTON,
  CLASS_NAMES,
} from './Footer-constants';

import { URLS } from '../../../../config';

const ViewComponent = ({ disabled }) => (
  <div className={CLASS_NAMES.container}>
    <Button.Text {...RESET_PASSWORD_BUTTON} disabled={disabled} />
    <div className={CLASS_NAMES.footer}>
      <Link to={URLS.LOGIN}>
        <Button.Link {...LOGIN_BUTTON} />
      </Link>
    </div>
  </div>
);

ViewComponent.defaultProps = {
  disabled: undefined,
};

ViewComponent.displayName = 'ForgotPassword-Footer-view';

ViewComponent.propTypes = {
  /**
   * Disabled
   */
  disabled: PropTypes.bool,
};

export default ViewComponent;
