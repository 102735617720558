import {
  useLoadData,
  EnterpriseUserAuthService,
  EnterprisePortalService,
} from '@kanda-api/library';

const ContainerComponent = ({ children }) => {
  const { data: isUserLoggedIn } = useLoadData([
    EnterpriseUserAuthService.key.isUserLoggedIn,
  ]);

  const { data: profile, isLoading } = useLoadData(
    isUserLoggedIn && [EnterprisePortalService.retrieveProfile],
  );

  return children({ isLoading, name: profile?.enterprise?.name });
};

ContainerComponent.displayName = 'Menu-container';

export default ContainerComponent;
