import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useHelpcrunch } from '@kanda-form-components/library';
import {
  useLoadData,
  EnterpriseUserAuthService,
  EnterprisePortalService,
} from '@kanda-api/library';
import { cleanObject } from '@kanda-utils/library';

import { URLS } from '../../config';

const ContainerComponent = ({ children }) => {
  const { data: isUserLoggedIn } = useLoadData([
    EnterpriseUserAuthService.key.isUserLoggedIn,
  ]);

  const { data: profile } = useLoadData(
    isUserLoggedIn && [EnterprisePortalService.retrieveProfile],
  );

  const { push, location } = useHistory();

  const { update } = useHelpcrunch();

  const homeLinkProps = { to: isUserLoggedIn ? URLS.HOME : URLS.LOGIN };

  useEffect(() => {
    if (!profile || !profile?.enterpriseUser?.isFirstTimeLogin) return;
    if (location.href === URLS.RESET_PASSWORD) return;
    push(URLS.RESET_PASSWORD);
  }, [push, location, profile]);

  useEffect(() => {
    if (!profile) return;
    const email = profile?.enterprise?.email;
    const enterpriseId = profile?.enterprise?.enterpriseId;
    const skipDeposit = profile?.enterprise?.skipDeposit;
    const companyName =
      profile?.enterprise?.name || profile?.enterpriseUser?.organizationName;
    const settings = cleanObject({
      name: companyName,
      email,
      user_id: enterpriseId,
      skip_deposit: skipDeposit,
      company: companyName,
    });
    update(settings);
  }, [profile, update]);

  return children({ homeLinkProps });
};

ContainerComponent.displayName = 'AppWrapper-container';

export default ContainerComponent;
