import React from 'react';
import { BreakPoints, Form, Loader } from '@kanda-form-components/library';

import Container from './Login-container';
import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = () => (
  <Container>
    {({ login, form, isSubmitting, ...restProps }) => (
      <Form form={form} onSubmit={login}>
        <BreakPoints
          mobile={<Mobile {...restProps} />}
          desktop={<Desktop {...restProps} />}
        />
        <Loader isLoading={isSubmitting} />
      </Form>
    )}
  </Container>
);

ViewComponent.displayName = 'Login-view';

export default ViewComponent;
