import React from 'react';
import PropTypes from 'prop-types';
import { DesktopLayout } from '@kanda-form-components/library';

import { Content, Footer } from '../components';

const ViewComponent = ({ disabled }) => (
  <DesktopLayout.Boxed>
    <DesktopLayout.Boxed.Content>
      <Content />
      <Footer disabled={disabled} />
    </DesktopLayout.Boxed.Content>
  </DesktopLayout.Boxed>
);

ViewComponent.defaultProps = {
  disabled: undefined,
};

ViewComponent.propTypes = {
  /**
   * Disabled
   */
  disabled: PropTypes.bool,
};

ViewComponent.displayName = 'ForgotPassword-Desktop-view';

export default ViewComponent;
