import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@kanda-form-components/library';

import {
  RESET_PASSWORD_BUTTON,
  CLASS_NAMES,
} from './Footer-constants';

const ViewComponent = ({ disabled }) => (
  <div className={CLASS_NAMES.container}>
    <Button.Text {...RESET_PASSWORD_BUTTON} disabled={disabled} />
  </div>
);

ViewComponent.defaultProps = {
  disabled: undefined,
};

ViewComponent.displayName = 'ResetPassword-Footer-view';

ViewComponent.propTypes = {
  /**
   * Disabled
   */
  disabled: PropTypes.bool,
};

export default ViewComponent;
