import { useEffect } from 'react';
import { KandaEnterpriseAuthService, useMutate } from '@kanda-api/library';

const ContainerComponent = () => {
  const { mutate: logout } = useMutate(KandaEnterpriseAuthService.logout);

  useEffect(() => logout(), [logout]);

  return null;
};

ContainerComponent.displayName = 'Logout-container';

export default ContainerComponent;
