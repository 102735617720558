const ContainerComponent = ({ children, customer }) => {
  const name = customer?.firstName
    ? `${customer?.firstName} ${customer?.lastName}`
    : '- -';

  return children({
    name,
  });
};

ContainerComponent.displayName = 'Customer-container';

export default ContainerComponent;
