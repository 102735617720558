import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Button } from '@kanda-form-components/library';

import { HEADER_TITLE } from './Header-constants';
import { URLS } from '../../../../../config';

const ViewComponent = () => (
  <Header.Base help className="text-style-f-em text-neutral-700">
    <Link key="close" to={URLS.HOME}>
      <Button.Icon icon="chevron-left" className="-ml-3 mr-3" />
    </Link>
    {HEADER_TITLE}
  </Header.Base>
);

ViewComponent.displayName = 'LoanInfo-Header-view';

export default ViewComponent;
