import { useContext, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { DEBOUNCE } from './DesktopSearch-constants';

import Context from '../../../../Context';

const ContainerComponent = ({ children }) => {
  const { query: initialQuery, setQuery: updateQuery } = useContext(Context);

  const [searchVisible, setSearchVisible] = useState(Boolean(initialQuery));
  const [query, setQuery] = useState(initialQuery);

  const autoFocusRef = useRef(false);

  const [debouncedQuery] = useDebounce(query, DEBOUNCE);

  /**
   * Sets filter
   */
  useEffect(() => {
    updateQuery(debouncedQuery);
  }, [debouncedQuery, updateQuery]);

  /**
   * Handles change
   * @param {Event} event
   */
  const onChange = (event) => setQuery(event.target.value);

  /**
   * Handles searchVisible
   */
  const onSearch = () => {
    autoFocusRef.current = true;

    setSearchVisible(true);
  };

  /**
   * Handles blur
   */
  const onBlur = () => {
    if (query) {
      return;
    }

    setSearchVisible(false);

    autoFocusRef.current = false;
  };

  return children({
    query,
    onChange,
    searchVisible,
    onSearch,
    onBlur,
    autoFocus: autoFocusRef.current,
  });
};

ContainerComponent.displayName = 'DesktopSearch-container';

export default ContainerComponent;
