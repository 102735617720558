import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@kanda-form-components/library';

import { CLASS_NAMES, BUTTON, ICON_PROPS } from './Desktop-constants';

const ViewComponent = ({ title, label, onClick, icon }) => (
  <div className={CLASS_NAMES.container}>
    <Icon {...ICON_PROPS} icon={icon} />
    <div className={CLASS_NAMES.info}>
      <p className={CLASS_NAMES.title}>{title}</p>
      <p className={CLASS_NAMES.label}>{label}</p>
    </div>
    <Button.Icon {...BUTTON} onClick={onClick} />
  </div>
);

ViewComponent.displayName = 'Filter-Mobile-view';

ViewComponent.propTypes = {
  /**
   * Title
   */
  title: PropTypes.string.isRequired,
  /**
   * Label
   */
  label: PropTypes.string.isRequired,
  /**
   * On click function
   */
  onClick: PropTypes.func.isRequired,
  /**
   * icon
   */
  icon: PropTypes.string.isRequired,
};

export default ViewComponent;
