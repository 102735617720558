export const PRICE_PROPS = {
  poundsClassName: 'text-20-24-em md:text-24-28-em',
  centsClassName: 'text-20-24-em md:text-24-28-em',
};

export const CLASS_NAMES = {
  container:
    'md:mt-6 md:ml-6 flex flex-col md:flex-row flex-1 max-w-120 justify-between ',
  row: 'flex flex-col mb-3 md:mb-0 last:mb-0 md:mr-4 last:mr-0',
  heading: 'text-12-18 md:text-16-24 text-green-400 mb-1 md:mb-0',
};
