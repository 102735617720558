import * as Yup from 'yup';

export const RESET_PASSWORD_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .required('You must provide a password')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      'Password must contain at least one uppercase letter, one lowercase letter and one number',
    ),
  confirmPassword: Yup.string()
    .required('You must confirm your password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
