import React from 'react';
import PropTypes from 'prop-types';
import { TimeAgo } from '@kanda-form-components/library';

import Container from './Activity-container';

const ViewComponent = ({ timestamp, status }) => (
  <Container status={status}>
    {({ statusLabel, classNames }) => (
      <p className={classNames.label}>
        <span>{statusLabel}</span> <TimeAgo timestamp={timestamp} />
      </p>
    )}
  </Container>
);

ViewComponent.displayName = 'Activity-view';

ViewComponent.propTypes = {
  /**
   * timestamp
   */
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /**
   * Status
   */
  status: PropTypes.string.isRequired,
};

export default ViewComponent;
