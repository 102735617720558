import React from 'react';
import { BreakPoints } from '@kanda-form-components/library';

import Mobile from './Mobile';
import Desktop from './Desktop';

const ViewComponent = (props) => (
  <BreakPoints
    mobile={<Mobile {...props} />}
    desktop={<Desktop {...props} />}
  />
);

ViewComponent.displayName = 'Banner-FilterButton-View';

export default ViewComponent;
