const ContainerComponent = ({ children, loan }) => {
  const { deposit, price } = loan || { deposit: 0, price: 0 };

  return children({
    deposit,
    price,
    loanValue: deposit ? price - deposit : undefined,
  });
};

ContainerComponent.displayName = 'LoanDetails-Breakdown-container';

export default ContainerComponent;
