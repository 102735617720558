import React from 'react';
import { Card, Icon, Button } from '@kanda-form-components/library';
import { Link } from 'react-router-dom';

import {
  TITLE,
  DESCRIPTION,
  CLASS_NAMES,
  ICON_PROPS,
  BUTTON_PROPS,
} from './NoResults-constants';
import { URLS } from '../../../../../config';

const ViewComponent = () => (
  <Card>
    <div className={CLASS_NAMES.content}>
      <Icon {...ICON_PROPS} />
      <p className={CLASS_NAMES.title}>{TITLE}</p>
      <p className={CLASS_NAMES.description}>{DESCRIPTION}</p>
      <Link to={URLS.NEW_APPLICATION}>
        <Button.Text {...BUTTON_PROPS} />
      </Link>
    </div>
  </Card>
);

ViewComponent.displayName = 'Jobs-Desktop-JobsPlaceholder-view';

export default ViewComponent;
